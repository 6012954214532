import { bindActionCreators } from "redux";
import * as HomeAction from "../../redux/actions/action.home";
import { connect } from "react-redux";
import { Component } from "react";
import { trackPromise } from "react-promise-tracker";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      formObj: {
        clientId : "",
        firstname: "",
        middleName: "",
        lastName: "",
        address: "",
        emailAddress: "",
        mobileNumber: "",
        apt: "",
        city: "",
        state: "",
        zipcode: "",
        cartId: "",
        totalPurchaseAmt: "",
        downPayment: "",
      },
      clientIdErr: "",
        firstnameErr: "",
        lastNameErr: "",
        addressErr: "",
        emailAddressErr: "",
        mobileNumberErr: "",
        cityErr: "",
        stateErr: "",
        zipcodeErr: "",
        cartIdErr: "",
        totalPurchaseAmtErr: "",
        downPaymentErr: "",
      toURL: "",
    };
  }

  onChange(event) {
    event.preventDefault();
    let fieldName = event.target.id;
    let fieldValue = event.target.value;
    if (fieldName === "clientId") {
      const re = /^[0-9\b]+$/;
      if (fieldValue === "" || re.test(fieldValue)) {
        this.setState({
          formObj: { ...this.state.formObj, clientId: fieldValue },
          clientIdErr : ""
        });
      }
    } else if (fieldName === "firstName") {
      fieldValue = fieldValue.replace(",", "");
      const re = /^(?!\s)[-a-zA-Z ]*$/;
      if (fieldValue === "" || re.test(fieldValue)) {
        this.setState({
          formObj: { ...this.state.formObj, firstname: fieldValue },
          firstnameErr : ""
        });
      }
    } else if (fieldName === "middleName") {
      fieldValue = fieldValue.replace(",", "");
      const re = /^(?!\s)[-a-zA-Z ]*$/;
      if (fieldValue === "" || re.test(fieldValue)) {
        this.setState({
          formObj: { ...this.state.formObj, middleName: fieldValue },
        });
      }
    } else if (fieldName === "lastname") {
      fieldValue = fieldValue.replace(",", "");
      const re = /^(?!\s)[-a-zA-Z ]*$/;
      if (fieldValue === "" || re.test(fieldValue)) {
        this.setState({
          formObj: { ...this.state.formObj, lastName: fieldValue },
          lastNameErr : ""
        });
      }
    } else if (fieldName === "emailAddress") {
      this.setState({
        formObj: { ...this.state.formObj, emailAddress: fieldValue },
        emailAddressErr : ""
      });
    } else if (fieldName === "mobileNumber") {
      const re = /^[0-9\b]+$/;
      if (fieldValue === "" || re.test(fieldValue)) {
        this.setState({
          formObj: { ...this.state.formObj, mobileNumber: fieldValue },
          mobileNumberErr : ""
        });
      }
    } else if (fieldName === "address") {
      this.setState({
        formObj: { ...this.state.formObj, address: fieldValue },
        addressErr : ""
      });
    } else if (fieldName === "apt") {
      this.setState({
        formObj: { ...this.state.formObj, apt: fieldValue },
      });
    } else if (fieldName === "city") {
      fieldValue = fieldValue.replace(",", "");
      const re = /^(?!\s)[-a-zA-Z ]*$/;
      if (fieldValue === "" || re.test(fieldValue)) {
        this.setState({
          formObj: { ...this.state.formObj, city: fieldValue },
          cityErr : ""
        });
      }
    } else if (fieldName === "state") {
      fieldValue = fieldValue.replace(",", "");
      const re = /^(?!\s)[-a-zA-Z ]*$/;
      if (fieldValue === "" || re.test(fieldValue)) {
        this.setState({
          formObj: { ...this.state.formObj, state: fieldValue },
          stateErr : ""
        });
      }
    } else if (fieldName === "zipCode") {
      const re = /^[0-9\b]+$/;
      if (fieldValue === "" || re.test(fieldValue)) {
        this.setState({
          formObj: { ...this.state.formObj, zipcode: fieldValue },
          zipcodeErr : ""
        });
      }
    } else if (fieldName === "cartId") {
      this.setState({
        formObj: { ...this.state.formObj, cartId: fieldValue },
        cartIdErr : ""
      });
    } 
    // else if (fieldName === "totalPurchaseAmt") {
    //   const re = /^[0-9\b]+$/;
    //   if (fieldValue === "" || re.test(fieldValue)) {
    //     this.setState({
    //       formObj: { ...this.state.formObj, totalPurchaseAmt: fieldValue },
    //       totalPurchaseAmtErr : ""
    //     });
    //   }
    // }
     else if (fieldName === "downPayment") {
      this.setState({
        formObj: { ...this.state.formObj, downPayment: fieldValue },
        downPaymentErr : ""
      });
    }
  }

  validateField() {
    let ret = true;
    if(this.state.formObj.clientId === "") {
      this.setState({ clientIdErr : "* Required."})
      ret=false
    }
    if(this.state.formObj.firstname === "") {
      this.setState({ firstnameErr : "* Required."})
      ret=false
    }
    if(this.state.formObj.lastName === "") {
      this.setState({ lastNameErr : "* Required."})
      ret=false
    }
    if(this.state.formObj.emailAddress === "") {
      this.setState({ emailAddressErr : "* Required."});
      ret=false;
    }
    if(this.state.formObj.mobileNumber === "") {
      this.setState({ mobileNumberErr : "* Required."});
      ret=false;
    }
    if(this.state.formObj.address === "") {
      this.setState({ addressErr : "* Required."});
      ret=false;
    }
    if(this.state.formObj.city === "") {
      this.setState({ cityErr : "* Required."});
      ret=false;
    }
    if(this.state.formObj.state === "") {
      this.setState({ stateErr : "* Required."});
      ret=false;
    }
    if(this.state.formObj.zipcode === "") {
      this.setState({ zipcodeErr : "* Required."});
      ret=false;
    }
    // if(this.state.formObj.cartId === "") {
    //   this.setState({ cartIdErr : "* Required."});
    //   ret=false;
    // }
    if(this.state.formObj.downPayment === "") {
      this.setState({ downPaymentErr : "* Required."});
      ret=false;
    }
    return ret;
  }

  async generateURL() {
    if(this.validateField()) {
      let clientID = this.state.formObj.clientId;
      await trackPromise(
        this.props.action.generateToken(clientID).then((response) => {
          if(response) {
            if(response?.errorMessage === "Invalid client") {
              this.setState({ clientIdErr : "Invalid client ID"});
            }
            else {
              localStorage.setItem("token" , response?.token);
            }
          }
          else {
            this.setState({ clientIdErr : "Invalid client ID"});
          }
        })
      )
      if(this.state.clientIdErr === "") {
        let obj = {
          firstname: this.state.formObj?.firstname,
          middleName: this.state.formObj?.middleName,
          lastName: this.state.formObj?.lastName,
          address: this.state.formObj?.address,
          emailAddress: this.state.formObj?.emailAddress,
          mobileNumber: this.state.formObj?.mobileNumber,
          apt: this.state.formObj?.apt,
          city: this.state.formObj?.city,
          state: this.state.formObj?.state,
          zipcode: this.state.formObj?.zipcode,
          cartId: this.state.formObj?.cartId,
          totalPurchaseAmt: this.state.formObj?.totalPurchaseAmt,
          downPayment: this.state.formObj?.downPayment,
        };
        await trackPromise(
          this.props.action.createSession(obj).then((response) => {
            if (response) {
              this.setState({ toURL: response.url }, () => {
                document.getElementById("redirectLink")?.click();
              });
            }
          })
        );
      }
    }
    
  }

  render() {
    return (
      <>
        <div className="container">
            <div className="row vh-100">
              <div className="col-12 col-md-8 offset-md-2 border-dark shadow p-5 rounded bg-white my-auto">
                  <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                    <label>Client ID (Ex : 001)</label>
                    {this.state.clientIdErr !== "" ? <span className="text-danger pl-2"> {this.state.clientIdErr}</span> : ""}
                    <input
                      className={"form-control "+(this.state.clientIdErr ? "border-danger" : "border-dark")}
                      id="clientId"
                      placeholder="Client ID"
                      value={this.state.formObj?.clientId}
                      onChange={(event) => this.onChange(event)}
                      required
                    />
                  </div>
                    </div>
                    <div className="col-4">
                    <div className="form-group">
                    <label>First Name</label>
                    {this.state.firstnameErr !== "" ? <span className="text-danger">{this.state.firstnameErr}</span> : ""}
                    <input
                      className="form-control border-dark"
                      id="firstName"
                      placeholder="First Name"
                      value={this.state.formObj?.firstname}
                      onChange={(event) => this.onChange(event)}
                      required
                    />
                  </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Middle Name (Optional)</label>
                        <input
                          className="form-control border-dark"
                          id="middleName"
                          placeholder="Middle Name"
                          value={this.state.formObj?.middleName}
                          onChange={(event) => this.onChange(event)}
                        />
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="form-group">
                        <label>Last Name</label>
                        {this.state.lastNameErr !== "" ? <span className="text-danger">{this.state.lastNameErr}</span> : ""}
                        <input
                          className="form-control border-dark"
                          id="lastname"
                          placeholder="Last Name"
                          value={this.state.formObj?.lastName}
                          onChange={(event) => this.onChange(event)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Email Address</label>
                    {this.state.emailAddressErr !== "" ? <span className="text-danger">{this.state.emailAddressErr}</span> : ""}
                    <input
                      type="email"
                      className="form-control border-dark"
                      id="emailAddress"
                      placeholder="Email Address"
                      value={this.state.formObj?.emailAddress}
                      onChange={(event) => this.onChange(event)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Mobile Number</label>
                    {this.state.mobileNumberErr !== "" ? <span className="text-danger">{this.state.mobileNumberErr}</span> : ""}
                    <input
                      className="form-control border-dark"
                      id="mobileNumber"
                      placeholder="Mobile Number"
                      value={this.state.formObj?.mobileNumber}
                      onChange={(event) => this.onChange(event)}
                      maxLength={10}
                    />
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <div className="form-group">
                        <label>Address</label>
                        {this.state.addressErr !== "" ? <span className="text-danger">{this.state.addressErr}</span> : ""}
                        <input
                          className="form-control border-dark"
                          id="address"
                          placeholder="Address"
                          value={this.state.formObj?.address}
                          onChange={(event) => this.onChange(event)}
                        />
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="form-group">
                        <label>Apt/Unit (As Needed)</label>
                        <input
                          className="form-control border-dark"
                          id="apt"
                          placeholder="Apt/Unit (As Needed)"
                          value={this.state.formObj?.apt}
                          onChange={(event) => this.onChange(event)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <div className="form-group">
                        <label>City</label>
                        {this.state.cityErr !== "" ? <span className="text-danger">{this.state.cityErr}</span> : ""}
                        <input
                          className="form-control border-dark"
                          id="city"
                          placeholder="City"
                          value={this.state.formObj?.city}
                          onChange={(event) => this.onChange(event)}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>State</label>
                        {this.state.stateErr !== "" ? <span className="text-danger">{this.state.stateErr}</span> : ""}
                        <input
                          className="form-control border-dark"
                          id="state"
                          placeholder="State"
                          value={this.state.formObj?.state}
                          onChange={(event) => this.onChange(event)}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group">
                        <label>Zip Code</label>
                        {this.state.zipcodeErr !== "" ? <span className="text-danger">{this.state.zipcodeErr}</span> : ""}
                        <input
                          className="form-control border-dark"
                          id="zipCode"
                          placeholder="zipCode"
                          value={this.state.formObj?.zipcode}
                          onChange={(event) => this.onChange(event)}
                          maxLength="5"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label>Cart ID</label>
                        {this.state.cartIdErr !== "" ? <span className="text-danger">{this.state.cartIdErr}</span> : ""}
                        <input
                          className="form-control border-dark"
                          id="cartId"
                          placeholder="Cart ID"
                          value={this.state.formObj?.cartId}
                          onChange={(event) => this.onChange(event)}
                        />
                      </div>
                    </div>
                    {/* <div className="col-6">
                      <div className="form-group">
                        <label>Total Purchase Amount</label>
                        {this.state.totalPurchaseAmtErr !== "" ? <span className="text-danger">{this.state.totalPurchaseAmtErr}</span> : ""}
                        <input
                          className="form-control border-dark"
                          id="totalPurchaseAmt"
                          placeholder="Total Amount"
                          value={this.state.formObj?.totalPurchaseAmt}
                          onChange={(event) => this.onChange(event)}
                          maxLength="5"
                        />
                      </div>
                    </div> */}
                    <div className="col-6">
                      <div className="form-group">
                        <label>Down Payment</label>
                        {this.state.downPaymentErr !== "" ? <span className="text-danger">{this.state.downPaymentErr}</span> : ""}
                        <input
                          className="form-control border-dark"
                          id="downPayment"
                          placeholder="Down Payment"
                          value={this.state.formObj?.downPayment}
                          onChange={(event) => this.onChange(event)}
                          maxLength="5"
                        />
                      </div>
                    </div>
                  </div>
                  <button onClick={() => this.generateURL()} className="btn border-dark pt-3 pb-3 pl-5 pr-5 custombg-secondary customtext-primary w-100">
                    Generate URL
                  </button>
                <a
                  id="redirectLink"
                  href={this.state.toURL}
                  target="_blank"
                  rel="noreferrer"
                  hidden={true}
                >
                  {this.state.toURL}
                </a>
              </div>
            </div>
          </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(HomeAction, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(Home);

