import HttpMethods from "../redux/_http.methods";
import axios from "axios";
import Auth from "../redux/_http.services.js";
import APIFailedMessage from "../components/component.apiFailedMessage";


export class ApiWrapper {
  static async LoadData(data) {
    let shopifyBaseURL = "";
    let shopifyURL = "";
    let appsettings = JSON.parse(localStorage.getItem("shopify_appsettings"));
    if (!appsettings) {
      await axios.get("/appsettings.json").then((response) => {
        appsettings = response.data;
        localStorage.setItem("shopify_appsettings", JSON.stringify(appsettings));
        shopifyBaseURL = appsettings.links.shopify;
      });
    } else {
      shopifyBaseURL = appsettings.links.shopify;
    }
    shopifyURL = shopifyBaseURL + data.ResourceUrl;
    switch (data.MethodType) {
      case HttpMethods.Get():
        if (data) {
          if (data.anonymouseApi) {
            return axios
              .get(`${shopifyURL}`)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          } if(data.thirdPartyApi){
            return axios.get(`${data.ResourceUrl}`)
            .then((res) => {
              return res.data;
            })
            .catch((error) => {
              APIFailedMessage.displayError(error);
            });
          } else {
            return Auth.axiosMethod()
              .get(shopifyURL)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
        }
        break;
      case HttpMethods.Post():
        if (data.Resource) {
          if (data.anonymouseApi) {
            return axios
              .post(shopifyURL, data.Resource)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          } else {
            return Auth.axiosMethod()
              .post(shopifyURL, data.Resource)
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
        }
        break;
      default:
        break;
    }
  }
}
