import "./App.css";
import CustomRoutes from "./router/router";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { usePromiseTracker } from "react-promise-tracker";
import {ThreeDots} from "react-loader-spinner";
import { ToastContainer} from 'react-toastify';

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ThreeDots type="ThreeDots" color="#5C068C" height="100" width="100" />
      </div>
    )
  );
};

function App() {
  const { promiseInProgress } = usePromiseTracker();
  return (
    <>
      <div
        id="App"
        className={`${promiseInProgress ? "overlay" : "without_overlay"}`}
      >
        <CustomRoutes />
        <LoadingIndicator />
        <ToastContainer  limit={10} theme="colored"/>
      </div>
    </>
  );
}

export default App;

