import { STORE_TOKEN } from "../actions/actionTypes";
import { initialState } from "../initialState";

//storing token for api call authorization 
export const storeTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_TOKEN:
      return {...state, token: action.payload};
    default:
      return state;
  }
};
