import { STORE_APPSETTING_FILE } from "../actions/actionTypes";
import { initialState } from "../initialState";

//storing appSetting.json file for getting base url
export const storeAppSettingFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_APPSETTING_FILE:
      return {...state, appSettingFile: action.payload};
    default:
      return state;
  }
};
