import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import ConfirmDialog from "./component.confirmDialog";

class AugmentesIdleTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeOutModel: false,
    };
    this.idleTimer = null;
    this.sessionTimeOut = null;
    this.onIdle = this.onIdle.bind(this);
    this.onAction = this.onAction.bind(this);
    this.onActive = this.onActive.bind(this);
  }

  handleTimeModal = () => {
    this.setState({ timeOutModel: !this.state.timeOutModel });
    clearTimeout(this.sessionTimeOut.current);
  };

  onIdle(e) {
    this.setState({ timeOutModel: true });
    this.sessionTimeOut.current = setTimeout(() => {
      window.location = "https://www.danielsjewelers.com/";
    }, 20000);
  }
  onAction(e) {
    clearTimeout(this.sessionTimeOut.current);
  }
  onActive(e) {
    clearTimeout(this.sessionTimeOut.current);
  }

  render() {
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
            this.sessionTimeOut = ref;
          }}
          onIdle={this.onIdle}
          onAction={this.onAction}
          onActive={this.onActive}
          timeout={60000 * 58}
        />
        <ConfirmDialog
          show={this.state.timeOutModel}
          handleModalPopup={this.handleTimeModal}
          message={
            "<p className='mb-0'>Your session is about to expire due to inactivity, please choose to stay In.</p><p className='mb-0'>Otherwise, please close the tab and reopen.</p>"
          }
          header_name="Warning!!"
          button2_name="Stay In"
          button2={this.handleTimeModal}
        />
      </>
    );
  }
}
export default AugmentesIdleTime;

