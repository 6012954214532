import { faGem, faMoon, faSquare, faStar, faStarOfLife } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalBody } from "react-bootstrap";

const CongratulationDialog = (props) => {
    const {showCongratulationModal, downPaymentAmt, paymentPerMonth, handleContinue, handleCancel}=props;
    return (
        <>
            <Modal id="congratulationModal" show={showCongratulationModal} centered size="lg">
                <ModalBody>
                    <span id="oval"></span>
                    <FontAwesomeIcon icon={faGem} id="gemIcon" className="customtext-primary" size="5x"/>

                    <FontAwesomeIcon icon={faStar} id="starIcon" className="customtext-primary position-absolute" size="2x"/>
                    <FontAwesomeIcon icon={faStarOfLife} id="startLifeIcon" className="customtext-primary position-absolute" size="3x"/>
                    <FontAwesomeIcon icon={faSquare} id="squaresIcon" className="customtext-primary position-absolute"/>
                    <FontAwesomeIcon icon={faMoon} id="moonIcon" className="customtext-primary position-absolute" size="2x"/>

                    <FontAwesomeIcon icon={faStar} id="secStarIcon" className="customtext-primary position-absolute" />
                    <FontAwesomeIcon icon={faStarOfLife} id="secStartLifeIcon" className="customtext-primary position-absolute" size="3x"/>
                    <FontAwesomeIcon icon={faSquare} id="secSquaresIcon" className="customtext-primary position-absolute" size="2x"/>
                    <FontAwesomeIcon icon={faMoon} id="secMoonIcon" className="customtext-primary position-absolute" size="2x"/>

                    <h2 className="text-center text-success customMargin"><b>CONGRATULATION!</b></h2>
                    <p className="text-center mt-4">Your order is <b>PRE-APPROVED*</b> for Daniels Credit with a down payment today</p>
                    <div className="row mt-4">
                        <div className="col-6 text-right">
                        <span><b>Down payment : </b>${parseInt(downPaymentAmt).toFixed(2)}</span>
                        </div>
                        <div className="col-6">
                        <span ><b>Payment per month :</b> ${parseInt(paymentPerMonth).toFixed(2)}</span>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-6 text-right">
                        <button className="btn custombg-secondary customtext-primary pl-4 pr-4 pl-md-5 pr-md-5 pt-2 pb-2" onClick={() => handleContinue()}>CONTINUE</button>
                        </div>
                        <div className="col-6">
                        <button className="btn customtext-secondary pl-4 pr-4 pl-md-5 pr-md-5 pt-2 pb-2 cancelButton" onClick={() => handleCancel()}>CANCEL</button>
                        </div>
                    </div>
                    <div>
                    </div>
                </ModalBody>
            </Modal> 
        </>
    )
}

export default CongratulationDialog;