import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class APIFailedMessage {
  static displayError(error) {
    toast.error("Something went wrong, Please contact Administrator");
    toast.clearWaitingQueue();
  }
}

