import HttpMethods from "../_http.methods";
import { ApiWrapper } from "../../api/thunk.helper";

export function getCodeMaster(searchFilter) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: searchFilter,
      ResourceUrl: "Shopify/apply-now/code-master/" + searchFilter.type,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function adminSettings() {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      ResourceUrl: "Shopify/admin-setting"
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getIpAddress() {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      ResourceUrl:"https://api.ipify.org/",
      thirdPartyApi: true,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function creditSubmit(reqData, searchFilter) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: reqData,
      ResourceUrl:
        "Shopify/apply-now/credit/submit?SessionId=" +
        searchFilter.SessionId
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function mobilesendcodeormsg(searchFilter) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: searchFilter,
      ResourceUrl: "Shopify/twilio-mobile-sendcodeormsg",
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadAutoPay(searchObj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: searchObj,
      ResourceUrl: "Shopify/apply-now/credit/loadinfinity-autopay?_guid="+searchObj.cartId+"&fName="+searchObj.fname+"&lname="+searchObj.lname+"&zip="+searchObj.zipcode,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadDownPayment(searchObj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: searchObj,
      ResourceUrl: "Shopify/apply-now/credit/loadinfinity-downpayment?_guid="+searchObj.cartId+"&fName="+searchObj.fname+"&lname="+searchObj.lname+"&zip="+searchObj.zipcode+"&src="+searchObj.src+"&amount="+searchObj.amount,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function validateSessionId(searchFilter) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: searchFilter,
      ResourceUrl:
        "Shopify/validate-session?sessionId=" +
        encodeURIComponent(searchFilter),
      anonymouseApi: true,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

// export function getAddress(searchObj) {
//   return function (dispatch) {
//     const data = {
//       MethodType: HttpMethods.Get(),
//       ResourceUrl:
//         "ExpressAddress?format=JSON&id=" +
//         JSON.parse(localStorage.getItem("appsettings"))?.Keys
//           ?.addressAutoFillKey +
//         "&line1=" +
//         searchObj +
//         "&maxrecords=" +
//         Constants?.addressAutoFillMaxRecord +
//         "&opt=" +
//         Constants?.addressAutoFillOptions +
//         "&cols=" +
//         Constants?.addressAutoFillCols,
//       isAutoFill: true,
//     };
//     return ApiWrapper.LoadData(data)
//       .then((response) => {
//         return response;
//       })
//       .catch((error) => {
//         throw error;
//       });
//   };
// }

export function sendDataToShopify(obj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: obj,
      ResourceUrl: "Shopify/SendDataToShopify",
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function validateCaptcha(token) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      ResourceUrl: "Shopify/SendGoogleCaptcha",
      Resource: token,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}