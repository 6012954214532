import HttpMethods from "../_http.methods";
import { ApiWrapper } from "../../api/thunk.helper";

export function generateToken(searchObj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      ResourceUrl: "Shopify/GetToken/"+searchObj,
      anonymouseApi: true,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function createSession(obj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      ResourceUrl: "Shopify/create-session",
      Resource: obj,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  };
}
