import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../modules/HomePage/component.Home";
import ShopifyApplication from "../modules/ShopifyApplication/component.shopifyApplication";
import componentShopifyApplyURL from "../modules/ShopifyApplyURL/component.shopifyApplyURL";

const CustomRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={componentShopifyApplyURL}></Route>
        <Route path="/apply-now" Component={ShopifyApplication}></Route>
        <Route path="/home" element={<Home />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default CustomRoutes;

