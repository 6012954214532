import React from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export default class ConfirmDialog extends React.Component {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.handleModalPopup}>
        <Modal.Header className="pb-0">
          <b className="customtext-secondary h4">
            {this.props.header_name ? this.props.header_name : "Confirmation"}
          </b>
          <FontAwesomeIcon
            icon={faClose}
            className="btn"
            onClick={() => this.props.handleModalPopup()}
          />
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div dangerouslySetInnerHTML={{ __html: this.props.message }}></div>
        </Modal.Body>
        <Modal.Footer className="border-top-0 mt-1">
          {this.props.cancelPopup && (
            <p
              className="btn ml-1 mr-1 mb-0 text-white bg-warning reCancel"
              onClick={() => this.props.handleModalPopup()}
            >
              Cancel
            </p>
          )}
          {this.props.button1 && (
            <p
              className="btn ml-1 mr-1 mb-0 reStay"
              style={{ backgroundColor: "#1B1B1B", color: "white" }}
              onClick={this.props.button1}
            >
              {this.props.button1_name}
            </p>
          )}
          {this.props.button2 && (
            <button
              className="btn mr-1 ml-1 mb-0 reLogout"
              style={{ backgroundColor: "#5c068c", color: "white" }}
              onClick={this.props.button2}
            >
              {this.props.button2_name}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

