import { combineReducers } from "redux";
import { storeTokenReducer } from "./store_Token.reducer";
import { storeAppSettingFileReducer } from "./store_AppSetting_File.reducer";

const rootReducer = combineReducers({
  storeTokenReducer,
  storeAppSettingFileReducer
});

export default rootReducer;
