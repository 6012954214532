import React, { Component } from "react";
import { Card, ToastContainer, Toast, Modal } from "react-bootstrap";
import "./component.shopifyApplyURL.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import { trackPromise } from "react-promise-tracker";
import AugmentesIdleTime from "../../components/component.augmentesIdleTime";
// import ReCAPTCHA from "react-google-recaptcha";
import { IMaskInput } from "react-imask";
import axios from "axios";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import {
  formatPhoneNumber,
  GetDateFromMMYYYY,
} from "../../components/component.customFormatter";
import DanielsDisclosureForm from "./component.danielsDisclosureForm";
// import Autocomplete from 'react-autocomplete';
import * as shopifyActions from "../../redux/actions/actions.shopify";
import danielsLogo from "../../images/DanielBlack.png";
// import store from "../../redux/store";
// import * as ActionType from "../../redux/actions/actionTypes";
import CongratulationDialog from "../../components/component.congratulationDialog"; 
import { toast } from "react-toastify";
import * as HomeAction from "../../redux/actions/action.home";

class ShopifyApplyURL extends Component {
  constructor() {
    super();
    this.state = {
      step1: true,
      isStep1Complete: false,
      step2: false,
      toggled: false,
      isStep2Complete: false,
      step3: false,
      isStep3Complete: false,
      step4: false,
      isStep4Complete: false,
      step5: false,
      isStep5Complete: false,
      step6: false,
      isStep6Complete: false,
      step7: false,
      isStep7Complete: false,
      step8: false,
      danielsDisclosure: false,
      showPopup: false,
      customerInfo: [],
      showSubmitConfirmation: false,
      showSubmittedAlert: false,
      isAgreed: false,
      isAPIError: false,
      isAPIErrorMsg: "",
      isSMSError: false,
      isSMSErrorMsg: "",
      SMSCodeSent: false,
      autoPayIframeUrl: "",
      isAutoPayIframeError: false,
      isAutoPayIframeErrorMsg: "",
      isDownPaymentIframeError: false,
      isDownPaymentIframeErrorMsg: "",
      downPaymentIframeUrl: "",
      custIdEnt: "",
      autoPay: false,
      autoPaySubmitted: false,
      autoPayStatus: "",
      formObj: {
        customerNo: "NEW",
        suffix: "0",
        firstName: "",
        middleName: "",
        lastName: "",
        mailingAdr: "",
        emailAdr: "",
        aptUnit: "",
        dob: "",
        city: "",
        mobileNo: "",
        state: "0",
        secPhone: "",
        zipCode: "",
        empName: "",
        empStartDate: "",
        empPhoneNo: "",
        netIncome: "",
        duties: "",
        housingInfo: "0",
        ssn: "",
        primaryIdType: "0",
        primaryIdNo: "",
        primaryIdState: "0",
        relativeName: "",
        relativePhone: "",
        relativeRelation: "",
        verificationCode: "",
        lstSuffix: [],
        lstState: [],
        lstHousingInfo: [],
        lstPrimaryIdType: [],
        lstPrimaryIdState: [],
        totalPurchaseAmt: "",
        downPayment: "",
        downPaymentStatus : false,
        paymentPerMonth : "",
        paymentResponse : ""
      },
      formErrors: {},
      SMSLink: false,
      cartId: "0",
      isAutoPayRequired: false,
      sessionId: "",
      // captchaVerified: false,
      validateSessionIdFails: false,
      siteKey: "",
      autoPayModal: false,
      isSMSOverride: false,
      isSMSVerified: false,
      errorInAccVerification: false,
      errorInMobileInfo: false,
      errorInPersonalInfo: false,
      errorInEmployment: false,
      errorInIdentification: false,
      errorInDanielsFields: false,
      errorInAutoPay: false,
      errorInDisclosure: false,
      verificationBox: false,
      djoId: "",
      djoIdError: "",
      djoSubmitted: false,
      showTempCode: false,
      djoIdConfirm: false,
      allAddress: [],
      showCongratulationModal: false,
      offerAccepted: true,
      isPaymentEnable : true,
      showAppInProgress:false,
      timervalue : 5000,
    };
  }

  onElectronicAgree = (event) => {
    event.preventDefault();
    this.setState({ isAgreed: !this.state.isAgreed });
  };

  handleFormValidation(currStep) {
    let formErrors = {};
    let formIsValid = true;

    formErrors["mobileNoErr"] = "";
    formErrors["verificationCodeErr"] = "";
    formErrors["firstNameErr"] = "";
    formErrors["lastNameErr"] = "";
    formErrors["dobErr"] = "";
    formErrors["ssnErr"] = "";
    formErrors["secPhoneErr"] = "";
    formErrors["emailAdrErr"] = "";
    formErrors["mailingAdrErr"] = "";
    formErrors["aptUnitErr"] = "";
    formErrors["cityErr"] = "";
    formErrors["stateErr"] = "";
    formErrors["zipCodeErr"] = "";
    formErrors["housingInfoErr"] = "";
    formErrors["relativeNameErr"] = "";
    formErrors["relativePhoneErr"] = "";
    formErrors["relativeRelationErr"] = "";
    formErrors["empNameErr"] = "";
    formErrors["dutiesErr"] = "";
    formErrors["empStartDateErr"] = "";
    formErrors["empPhoneNoErr"] = "";
    formErrors["netIncomeErr"] = "";
    formErrors["primaryIdTypeErr"] = "";
    formErrors["primaryIdNoErr"] = "";
    formErrors["primaryIdStateErr"] = "";
    formErrors["autoPayErr"] = "";
    formErrors["captchaErr"] = "";
    formErrors["danielsDisclosureErr"] = "";

    if (currStep === "mobileNo") {
      if (this.state.formObj.mobileNo.length === 0) {
        formIsValid = false;
        formErrors["mobileNoErr"] = "Mobile number is required.";
      } else if (
        this.state.formObj.mobileNo.slice(0, 1) == 1 ||
        this.state.formObj.mobileNo.slice(0, 1) == 0
      ) {
        formIsValid = false;
        formErrors["mobileNoErr"] = "Mobile number cannot start with a 0 or 1.";
      } else if (this.state.formObj.mobileNo.length !== 12) {
        formIsValid = false;
        formErrors["mobileNoErr"] = "Mobile number must be 10 digit.";
      }
    }

    if (currStep === "verificationCodeCheck") {
      if (!this.state.isSMSOverride) {
        if (this.state.formObj.verificationCode.length === 0) {
          formIsValid = false;
          formErrors["verificationCodeErr"] =
            "We need to verify your cell phone. Please check your cell for the text message we just sent and enter the code above and click verify.";
        } else if (this.state.formObj.verificationCode.length !== 6) {
          formIsValid = false;
          formErrors["verificationCodeErr"] =
            "Verification code must be 6 digit.";
        }
      }
    }

    if (currStep === "codeMatchFailed") {
      formIsValid = false;
      formErrors["verificationCodeErr"] = "Invalid Code.";
    }

    if (currStep === "Step 1") {
      if (this.state.formObj.dob.length === 0) {
        formIsValid = false;
        formErrors["dobErr"] = "Date of birth is required.";
      } else if (parseInt(this.state.formObj.dob.split("/")[2]) < 1900) {
        formIsValid = false;
        formErrors["dobErr"] = "Year must be greater than 1900.";
      } else if (this.state.formObj.dob.length !== 0) {
        var today = new Date();
        var birthDate = new Date(this.state.formObj.dob);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if (age < 18) {
          formIsValid = false;
          formErrors["dobErr"] = "You must be 18 years or older to apply.";
        }
      }
      if (this.state.formObj.ssn.length === 0) {
        formIsValid = false;
        formErrors["ssnErr"] = "SSN/ITIN is required.";
      } else if (this.state.formObj.ssn.length < 11) {
        formIsValid = false;
        formErrors["ssnErr"] = "SSN/ITIN must be 9 digit number.";
      }
    }

    if (currStep === "Step 2") {
      if (!this.state.isSMSOverride) {
        if (this.state.formObj.verificationCode.length === 0) {
          formIsValid = false;
          formErrors["verificationCodeErr"] =
            "We need to verify your cell phone. Please check your cell for the text message we just sent and enter the code above and click verify.";
        } else if (this.state.formObj.verificationCode.length !== 6) {
          formIsValid = false;
          formErrors["verificationCodeErr"] =
            "Verification code must be 6 digit.";
        }
      }
      if (this.state.formObj.mobileNo.length === 0) {
        formIsValid = false;
        formErrors["mobileNoErr"] = "Mobile number is required.";
      } else if (
        this.state.formObj.mobileNo.slice(0, 1) == 1 ||
        this.state.formObj.mobileNo.slice(0, 1) == 0
      ) {
        formIsValid = false;
        formErrors["mobileNoErr"] = "Mobile number cannot start with a 0 or 1.";
      } else if (this.state.formObj.mobileNo.length !== 12) {
        formIsValid = false;
        formErrors["mobileNoErr"] = "Mobile number must be 10 digit.";
      }
    }

    if (currStep === "DJOaddressVerify") {
      formIsValid = false;
      formErrors["mailingAdrErr"] =
        "Please note address must reside in the above mentioned state only.";
    }
    if (currStep === "revertDJOaddressVerify") {
      formErrors["mailingAdrErr"] = undefined;
    }

    if (currStep === "Step 3") {
      if (this.state.formObj.firstName.length === 0) {
        formIsValid = false;
        formErrors["firstNameErr"] = "First name is required.";
      }
      if (this.state.formObj.lastName.length === 0) {
        formIsValid = false;
        formErrors["lastNameErr"] = "Last name is required.";
      }
      if (this.state.formObj.mailingAdr.length === 0) {
        formIsValid = false;
        formErrors["mailingAdrErr"] = "Mailing address is required.";
      } else {
        let mailaddr1 = this.state.formObj.mailingAdr
          ?.toLowerCase()
          ?.includes("p.o box");
        let mailaddr2 = this.state.formObj.mailingAdr
          ?.toLowerCase()
          ?.includes("p.obox");
        let mailaddr3 = this.state.formObj.mailingAdr
          ?.toLowerCase()
          ?.includes("po box");
        let mailaddr4 = this.state.formObj.mailingAdr
          ?.toLowerCase()
          ?.includes("pobox");
        let mailaddr5 = this.state.formObj.mailingAdr
          ?.toLowerCase()
          ?.includes("p.o. box");
        let mailaddr6 = this.state.formObj.mailingAdr
          ?.toLowerCase()
          ?.includes("po. box");
        if (
          mailaddr1 ||
          mailaddr2 ||
          mailaddr3 ||
          mailaddr4 ||
          mailaddr5 ||
          mailaddr6
        ) {
          formIsValid = false;
          formErrors["mailingAdrErr"] = "P.O Box is not accepted.";
        } else if (
          this.state.formObj.mailingAdr
            ?.split(" ")[0]
            ?.replace(/[0-9]/g, "") === "" &&
          this.state.formObj.mailingAdr?.trim()?.split(" ")?.length <= 1
        ) {
          formIsValid = false;
          formErrors["mailingAdrErr"] = "Please enter a valid mailing address.";
        } else if (
          this.state.formObj.mailingAdr?.trim()?.split(" ")?.length <= 1
        ) {
          formIsValid = false;
          formErrors["mailingAdrErr"] = "Please enter a valid mailing address.";
        } else {
          let mailaddr = this.state.formObj.mailingAdr?.split(" ");
          mailaddr = mailaddr?.filter((val) => {
            return val !== "";
          });
          if (mailaddr[1]?.replace(/[0-9]/g, "") === "") {
            formIsValid = false;
            formErrors["mailingAdrErr"] =
              "Please enter a valid mailing address.";
          }
        }
      }
      if (this.state.formObj.city.length === 0) {
        formIsValid = false;
        formErrors["cityErr"] = "City is required.";
      }
      if (parseInt(this.state.formObj.state) === 0) {
        formIsValid = false;
        formErrors["stateErr"] = "State is required.";
      } else if (
        this.state.formObj.lstState.find(
          (state) => this.state.formObj.state == state.id
        ) === undefined
      ) {
        formIsValid = false;
        formErrors["stateErr"] = "State is required.";
      }

      if (this.state.formObj.zipCode.length === 0) {
        formIsValid = false;
        formErrors["zipCodeErr"] = "Zip code is required.";
      } else if (this.state.formObj.zipCode.length < 5) {
        formIsValid = false;
        formErrors["zipCodeErr"] = "Zip code must be 5 digit.";
      }
      if (parseInt(this.state.formObj.housingInfo) === 0) {
        formIsValid = false;
        formErrors["housingInfoErr"] = "Housing information is required.";
      }
      if (
        this.state.formObj.secPhone.length !== 0 &&
        this.state.formObj.secPhone.length !== 12
      ) {
        formIsValid = false;
        formErrors["secPhoneErr"] = "Secondary Phone must be 10 digit number.";
      } else if (
        this.state.formObj.secPhone.length !== 0 &&
        (this.state.formObj.secPhone.slice(0, 1) == 1 ||
          this.state.formObj.secPhone.slice(0, 1) == 0)
      ) {
        formIsValid = false;
        formErrors["secPhoneErr"] =
          "Secondary phone number cannot start with a 0 or 1.";
      } else if (this.state.formObj.mobileNo === this.state.formObj.secPhone) {
        formIsValid = false;
        formErrors["secPhoneErr"] =
          "Secondary phone number and mobile number cannot be same.";
      }
      if (this.state.formObj.emailAdr.length === 0) {
        formIsValid = false;
        formErrors["emailAdrErr"] = "Email address is required.";
      } else {
        let res = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!res.test(this.state.formObj.emailAdr)) {
          formIsValid = false;
          formErrors["emailAdrErr"] = "Enter a valid email address.";
        }
      }
    }

    if (currStep === "Step 4") {
      if (this.state.formObj.empName.length === 0) {
        formIsValid = false;
        formErrors["empNameErr"] = "Employer's name is required.";
      }
      if (this.state.formObj.duties.length === 0) {
        formIsValid = false;
        formErrors["dutiesErr"] = "Duties is required.";
      }
      if (this.state.formObj.empStartDate.length === 0) {
        formIsValid = false;
        formErrors["empStartDateErr"] = "Employment start date is required.";
      } else {
        var empDate = this.state.formObj.empStartDate;
        empDate = new Date(
          empDate.split("/")[1],
          empDate.split("/")[0],
          this.state.formObj.dob.split("/")[1]
        );
        var birthDate = new Date(this.state.formObj.dob);
        if (birthDate > empDate) {
          formIsValid = false;
          formErrors["empStartDateErr"] =
            "Employment start date could not be before birth date.";
        }
      }
      if (this.state.formObj.empPhoneNo.length === 0) {
        formIsValid = false;
        formErrors["empPhoneNoErr"] = "Employer's phone number is required.";
      } else if (this.state.formObj.empPhoneNo.length < 12) {
        formIsValid = false;
        formErrors["empPhoneNoErr"] =
          "Employer's phone number must be 10 digit.";
      } else if (
        this.state.formObj.empPhoneNo.slice(0, 1) == 1 ||
        this.state.formObj.empPhoneNo.slice(0, 1) == 0
      ) {
        formIsValid = false;
        formErrors["empPhoneNoErr"] =
          "Phone number cannot start with a 0 or 1.";
      }
      if (this.state.formObj.netIncome?.length === 0) {
        formIsValid = false;
        formErrors["netIncomeErr"] = "Net income is required.";
      } else if (this.state.formObj.netIncome < 100) {
        formIsValid = false;
        formErrors["netIncomeErr"] = "Net income must be greater than $100.";
      } else if (this.state.formObj.netIncome > 99999) {
        formIsValid = false;
        formErrors["netIncomeErr"] = "Net income must be lesser than $99999.";
      }
    }

    if (currStep === "Step 5") {
      if (parseInt(this.state.formObj.primaryIdType) === 0) {
        formIsValid = false;
        formErrors["primaryIdTypeErr"] = "Primary ID Type is required.";
      }
      if (this.state.formObj.primaryIdNo.length === 0) {
        formIsValid = false;
        formErrors["primaryIdNoErr"] = "Primary ID number is required.";
      }
      else if (this.state.formObj.primaryIdNo.length < 3) {
        formIsValid = false;
        formErrors["primaryIdNoErr"] = "Primary ID number should contain at least 3 characters.";
      }
      if (parseInt(this.state.formObj.primaryIdState) === 0) {
        formIsValid = false;
        formErrors["primaryIdStateErr"] = "Primary ID state is required.";
      }
    }

    if (currStep === "Step 6") {
      if (this.state.formObj.relativeName.length === 0) {
        formIsValid = false;
        formErrors["relativeNameErr"] = "Relative name is required.";
      }
      if (this.state.formObj.relativePhone.length === 0) {
        formIsValid = false;
        formErrors["relativePhoneErr"] = "Relative phone number is required.";
      } else if (this.state.formObj.relativePhone.length < 12) {
        formIsValid = false;
        formErrors["relativePhoneErr"] =
          "Relative Phone number must be 10 digit.";
      } else if (
        this.state.formObj.relativePhone.slice(0, 1) == 1 ||
        this.state.formObj.relativePhone.slice(0, 1) == 0
      ) {
        formIsValid = false;
        formErrors["relativePhoneErr"] =
          "Relative Phone number cannot start with a 0 or 1.";
      } else if (
        this.state.formObj.mobileNo === this.state.formObj.relativePhone
      ) {
        formIsValid = false;
        formErrors["relativePhoneErr"] =
          "Relative phone number and mobile number cannot be same.";
      } else if (
        this.state.formObj.secPhone === this.state.formObj.relativePhone
      ) {
        formIsValid = false;
        formErrors["relativePhoneErr"] =
          "Relative phone number and secondary phone number cannot be same.";
      }
      if (this.state.formObj.relativeRelation.length === 0) {
        formIsValid = false;
        formErrors["relativeRelationErr"] = "Relative relation is required.";
      }
    }

    if (currStep === "Step 7") {
      if (!this.state.danielsDisclosure) {
        formIsValid = false;
        formErrors["danielsDisclosureErr"] =
          "Disclosure agreement is required.";
      }
      // if (this.state.captchaVerified === false) {
      //   formIsValid = false;
      //   formErrors["captchaErr"] = "Captcha verification is required.";
      // }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  // verifyCaptcha = (response) => {
  //   if (response) {
  //     this.setState({
  //       captchaVerified: true,
  //     });
  //   }
  // };

  handleSubmittedAlert(event) {
    event.preventDefault();
    this.setState({ showSubmittedAlert: !this.state.showSubmittedAlert });
  }

  onDanielsDisclosureChecked() {
    this.setState({ danielsDisclosure: !this.state.danielsDisclosure });
  }
  onAutoPayChecked() {
    this.setState({ autoPay: !this.state.autoPay });
  }
  // augmentAutoPay() {
  //     this.setState({ autoPayModal: !this.state.autoPayModal });
  // }
  onDanielsDisclosureClicked = (event) => {
    event.preventDefault();
    this.setState({ showPopup: !this.state.showPopup });
  };

  isAgreedRevort = () => {
    this.setState({ isAgreed: false });
  };

  goStep1(event) {
    event.preventDefault();
    if (this.state.isStep1Complete) {
      this.setState({ step1: !this.state.step1 });
    }
  }

  goStep2(event) {
    event.preventDefault();
    if (this.state.isStep2Complete) {
      this.setState({ step2: !this.state.step2 });
    }
  }

  goStep3(event) {
    event.preventDefault();
    if (this.state.isStep3Complete) {
      this.setState({ step3: !this.state.step3 });
    }
  }

  goStep4(event) {
    event.preventDefault();
    if (this.state.isStep4Complete) {
      this.setState({ step4: !this.state.step4 });
    }
  }

  goStep5(event) {
    event.preventDefault();
    if (this.state.isStep5Complete) {
      this.setState({ step5: !this.state.step5 });
    }
  }

  goStep6(event) {
    event.preventDefault();
    if (this.state.isStep6Complete) {
      this.setState({ step6: !this.state.step6 });
    }
  }

  goStep7(event) {
    event.preventDefault();
    if (this.state.isStep7Complete) {
      this.setState({ step7: !this.state.step7 });
    }
  }

  submitMobileNo(event) {
    event.preventDefault();
    this.setState({ isSMSErrorMsg: "" });
    if (!this.handleFormValidation("Step 1")) {
      this.setState({ errorInAccVerification: true });
    }
    else if (this.handleFormValidation("mobileNo")) {
      let obj = {
        firstName:
          this.state.formObj.firstName === ""
            ? "New"
            : this.state.formObj.firstName,
        lastName:
          this.state.formObj.lastName === ""
            ? "User"
            : this.state.formObj.lastName,
        mobileNo: this.state.formObj.mobileNo,
        message: "",
        code: "",
        sendRica: false,
        countryCode: "+1",
      };
      trackPromise(
        this.props.actions
          .mobilesendcodeormsg(obj)
          .then((response) => {
            if (response) {
              this.setState({ isSMSError: false, isSMSErrorMsg: "" });
              if (response.twilioStatus?.toLowerCase() === "pending") {
                this.setState({
                  SMSCodeSent: true,
                });
                toast.success("Verification code sent to phone.");
                this.txtVerificationCode.focus();
              } else if (response.errorMessage.length > 0) {
                this.setState({ isSMSError: true });
                this.setState({
                  isSMSErrorMsg:
                    "Error in connection, please try after sometime.",
                });
              }
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    }
  }

  submitStep1(event) {
    event.preventDefault();
    if (this.handleFormValidation("Step 1")) {
      this.setState({ step1: true, isStep1Complete: true, step2: true }, () => {
        document.getElementById("txtMobileNo")?.focus();
      });
    }
  }

  submitStep2(event) {
    event.preventDefault();
    if (!this.handleFormValidation("Step 1")) {
      this.setState({ errorInAccVerification: true });
    }
    else if (
      this.handleFormValidation("mobileNo") &&
      this.handleFormValidation("verificationCodeCheck")
    ) {
      if (this.state.showTempCode) {
        if (this.state.formObj.verificationCode === "010101") {
          this.setState({ isSMSOverride: true });
          if (this.handleFormValidation("Step 1")) {
            this.setState({ isSMSError: false, isSMSErrorMsg: "" });
            this.setState({ step2: true, isStep2Complete: true, step3: true });
            setTimeout(() => {
              document.getElementById("txtFirstName")?.focus();
            }, 200);
          }
        } else {
          let obj = {
            firstName:
              this.state.formObj.firstName === ""
                ? "New"
                : this.state.formObj.firstName,
            lastName:
              this.state.formObj.lastName === ""
                ? "User"
                : this.state.formObj.lastName,
            mobileNo: this.state.formObj.mobileNo,
            message: "",
            code: this.state.formObj.verificationCode,
            sendRica: false,
            countryCode: "+1",
          };
          trackPromise(
            this.props.actions
              .mobilesendcodeormsg(obj)
              .then((response) => {
                if (response) {
                  if (response.twilioStatus?.toLowerCase() === "approved") {
                    if (this.handleFormValidation("Step 1")) {
                      this.setState({
                        isSMSError: false,
                        isSMSErrorMsg: "",
                        isSMSVerified: true,
                      });
                      this.setState({ step2: true, isStep2Complete: true, step3: true });
                      setTimeout(() => {
                        document.getElementById("txtFirstName")?.focus();
                      }, 200);
                    }
                  } else if (
                    response.twilioStatus?.toLowerCase() === "pending"
                  ) {
                    this.handleFormValidation("codeMatchFailed");
                  }
                }
              })
              .catch((err) => {
                APIFailedMessage.displayError(err);
              })
          );
        }
      } else {
        let obj = {
          firstName:
            this.state.formObj.firstName === ""
              ? "New"
              : this.state.formObj.firstName,
          lastName:
            this.state.formObj.lastName === ""
              ? "User"
              : this.state.formObj.lastName,
          mobileNo: this.state.formObj.mobileNo,
          message: "",
          code: this.state.formObj.verificationCode,
          sendRica: false,
          countryCode: "+1",
        };
        trackPromise(
          this.props.actions
            .mobilesendcodeormsg(obj)
            .then((response) => {
              if (response) {
                if (response.twilioStatus?.toLowerCase() === "approved") {
                  if (this.handleFormValidation("Step 1")) {
                    this.setState({
                      isSMSError: false,
                      isSMSErrorMsg: "",
                      isSMSVerified: true,
                    });
                    this.setState({ step2: true, isStep2Complete: true, step3: true });
                    setTimeout(() => {
                      document.getElementById("txtFirstName")?.focus();
                    }, 200);
                  }
                } else if (
                  response.twilioStatus?.toLowerCase() === "pending" ||
                  response.twilioStatus === null
                ) {
                  this.handleFormValidation("codeMatchFailed");
                }
              }
            })
            .catch((err) => {
              APIFailedMessage.displayError(err);
            })
        );
      }
    }
  }

  submitStep3(event) {
    event.preventDefault();
    if (!this.handleFormValidation("Step 1")) {
      this.setState({ errorInAccVerification: true });
    }
    if (
      this.handleFormValidation("Step 1") &&
      this.handleFormValidation("Step 3")
    ) {
      this.setState({ step3: true, isStep3Complete: true, step4: true }, () => {
        document.getElementById("txtEmpName")?.focus();
      });
    }
  }

  submitStep4(event) {
    event.preventDefault();
    if (!this.handleFormValidation("Step 1")) {
      this.setState({ errorInAccVerification: true });
    }
    if (!this.handleFormValidation("Step 3")) {
      this.setState({ errorInPersonalInfo: true });
    }
    if (
      this.handleFormValidation("Step 1") &&
      this.handleFormValidation("Step 3") &&
      this.handleFormValidation("Step 4")
    ) {
      this.setState({ step4: true, isStep4Complete: true, step5: true }, () => {
        document.getElementById("cmbPrimaryIdType")?.focus();
      });
    }
  }

  submitStep5(event) {
    event.preventDefault();
    if (!this.handleFormValidation("Step 1")) {
      this.setState({ errorInAccVerification: true });
    }
    if (!this.handleFormValidation("Step 3")) {
      this.setState({ errorInPersonalInfo: true });
    }
    if (!this.handleFormValidation("Step 4")) {
      this.setState({ errorInEmployment: true });
    }
    if (
      this.handleFormValidation("Step 1") &&
      this.handleFormValidation("Step 3") &&
      this.handleFormValidation("Step 4") &&
      this.handleFormValidation("Step 5")
    ) {
      this.setState({ step5: true, isStep5Complete: true, step6: true }, () => {
        document.getElementById("txtRelativeName")?.focus();
      });
    }
  }

  submitStep6(event) {
    event.preventDefault();
    if (!this.handleFormValidation("Step 1")) {
      this.setState({ errorInAccVerification: true });
    }
    if (!this.handleFormValidation("Step 3")) {
      this.setState({ errorInPersonalInfo: true });
    }
    if (!this.handleFormValidation("Step 4")) {
      this.setState({ errorInEmployment: true });
    }
    if (!this.handleFormValidation("Step 5")) {
      this.setState({ errorInIdentification: true });
    }
    if (
      this.handleFormValidation("Step 1") &&
      this.handleFormValidation("Step 3") &&
      this.handleFormValidation("Step 4") &&
      this.handleFormValidation("Step 5") &&
      this.handleFormValidation("Step 6")
    ) {
      this.setState({ step6: true, isStep6Complete: true, step7: true }, () => {
        document.getElementById("chkDanielsDisclosure")?.focus();
      });
    }
  }

  async sendDataToShopify(val, app_Id) {
    let obj = {
      "application_id" : app_Id,
      "isSuccessful": this.state.formObj.downPaymentStatus,
      "cartId": this.state.cartId,
      "downPayment": this.state.formObj.downPayment,
      "customerPII": (val === "submit") ? this.state.formObj.primaryIdNo : "",
      "danielsAccNum" : "",
      "downPayTransKey" : "",
      "tempusTransSerialNum" : "",
    };
    let result = await this.props.actions.sendDataToShopify(obj);
      if(result) {
        return result;
      }
  }

  async submitStep7() {
    this.setState({ isAPIError: false, showAppInProgress:!this.state.showAppInProgress, showSubmitConfirmation : false });
    // this.setState({
    //   showSubmitConfirmation: !this.state.showSubmitConfirmation,
    // });
    let app_Id="0";
      let obj1 = {
        firstName: this.state.formObj.firstName,
        middleName: this.state.formObj.middleName,
        lastName: this.state.formObj.lastName,
        suffix_Id:
          parseInt(this.state.formObj.suffix) > 0
            ? parseInt(this.state.formObj.suffix)
            : 0,
        address: this.state.formObj.mailingAdr,
        city: this.state.formObj.city,
        state_Id:
          parseInt(this.state.formObj.state) > 0
            ? parseInt(this.state.formObj.state)
            : 0,
        state_Id_Code:
          parseInt(this.state.formObj.state) > 0
            ? this.state.formObj.lstState.find(
                (obj) => obj.id == this.state.formObj.state
              )?.code
            : "",
        zipCode: this.state.formObj.zipCode,
        apt: this.state.formObj.aptUnit,
        emailAddress: this.state.formObj.emailAdr,
        birthDate: Moment(this.state.formObj.dob).format("YYYY-MM-DD"),
        mobileNo: this.state.formObj.mobileNo,
        secondaryPhone: this.state.formObj.secPhone,
        empName: this.state.formObj.empName,
        empPhone: this.state.formObj.empPhoneNo,
        howLong: GetDateFromMMYYYY(this.state.formObj.empStartDate),
        monthlyIncome: parseInt(this.state.formObj.netIncome),
        workJob: this.state.formObj.duties,
        housingInfo_Id: parseInt(this.state.formObj.housingInfo),
        createdBy_Associate: "",
        ssn: this.state.formObj.ssn,
        primaryTypeId: parseInt(this.state.formObj.primaryIdType),
        primaryTypeId_Code:
          parseInt(this.state.formObj.primaryIdType) > 0
            ? this.state.formObj.lstPrimaryIdType.find(
                (obj) => obj.id == this.state.formObj.primaryIdType
              )?.code
            : "",
        idNumber: this.state.formObj.primaryIdNo,
        primaryStateId: parseInt(this.state.formObj.primaryIdState),
        primaryStateId_Code:
          parseInt(this.state.formObj.primaryIdState) > 0
            ? this.state.formObj.lstPrimaryIdState.find(
                (obj) => obj.id == this.state.formObj.primaryIdState
              )?.code
            : "",
        relativeName: this.state.formObj.relativeName,
        relativePhone: this.state.formObj.relativePhone,
        relativeRelationship: this.state.formObj.relativeRelation,
        isMobileNoVerified: this.state.isSMSOverride ? false : true,
        isSMSCodeSent: this.state.SMSCodeSent ? true : false,
        termsAccepted: this.state.danielsDisclosure,
        dateLived: GetDateFromMMYYYY(Moment().format("MM/YYYY")),
        monthlyPayment: true,
        bankC: "",
        bankS: "",
        initialSale: 0,
        howOftenPaidId: 0,
        howOftenPaidId_Code: "",
        lastPayDay: "",
        nextPayDay: "",
        primaryIncomeId: 0,
        creditCardBin: "",
        creditCardExpDate: "",
        nameOnCreditCard: "",
        creditCardHomeAddress: false,
        ccAddress: "",
        ccApt: "",
        ccCity: "",
        ccStateId: 0,
        ccStateId_Code: "",
        ccZipCode: "",
        modifiedBy_Associate: "",
        primaryIdExpDate: "",
        secondIdType_Id: 0,
        secondIdIssuedBy_Id: "",
        secondIdExpDate: "",
        isSMSOverride: this.state.isSMSOverride,
        url: window.location.href,
        ipAddress: sessionStorage.getItem("ipAddress"),
        applicationVersion: "application.Version.0.0.1",
        isAutoPay: this.state.autoPay,
        custIdEnt: this.state.custIdEnt,
        autoPayStatus: this.state.autoPayStatus,
        type: "daniels_only",
        emailContent:
          "<h2>CUSTOMER INFORMATION - CREDIT APPLICATION</h2>" +
          "<h5>Mobile Information</h5>" +
          "<hr>" +
          "<b>Mobile Number : </b>" +
          this.state.formObj.mobileNo +
          "<br>" +
          "<br>" +
          "<h5>Personal Information</h5>" +
          "<hr>" +
          "<b>Store Code : </b> 100" +
          "<br>" +
          "<b>First Name : </b>" +
          this.state.formObj.firstName +
          "<br>" +
          "<b>Middle Name : </b>" +
          this.state.formObj.middleName +
          "<br>" +
          "<b>Last Name : </b>" +
          this.state.formObj.lastName +
          "<br>" +
          "<b>Suffix : </b>" +
          (parseInt(this.state.formObj.suffix) > 0
            ? this.state.formObj.lstSuffix.find(
                (suffix) => this.state.formObj.suffix == suffix.id
              )?.description
            : "") +
          "<br>" +
          "<b>Date of Birth (MM/DD/YYYY) : </b>" +
          Moment(this.state.formObj.dob).format("MM/DD/YYYY") +
          "<br>" +
          "<b>Social Security Number/ITIN# : </b>XXX-XX-" +
          this.state.formObj.ssn.slice(-4) +
          "<br>" +
          "<b>Secondary Phone : </b>" +
          (this.state.formObj.secPhone === ""
            ? ""
            : this.state.formObj.secPhone) +
          "<br>" +
          "<b>Email Address : </b>" +
          this.state.formObj.emailAdr +
          "<br>" +
          "<br>" +
          "<h5>Residence</h5>" +
          "<hr>" +
          "<b>Mailing Address : </b>" +
          this.state.formObj.mailingAdr +
          "<br>" +
          "<b>Apt/Unit (Optional) : </b>" +
          this.state.formObj.aptUnit +
          "<br>" +
          "<b>City : </b>" +
          this.state.formObj.city +
          "<br>" +
          "<b>State : </b>" +
          (parseInt(this.state.formObj.state) > 0
            ? this.state.formObj.lstState.find(
                (obj) => obj.id == this.state.formObj.state
              )?.description
            : "") +
          "<br>" +
          "<b>Zip Code : </b>" +
          this.state.formObj.zipCode +
          "<br>" +
          "<b>Housing Information : </b>" +
          this.state.formObj.lstHousingInfo?.find(
            (housingInfo) => this.state.formObj.housingInfo == housingInfo.id
          )?.description +
          "<br>" +
          "<b>Nearest Relative Name : </b>" +
          this.state.formObj.relativeName +
          "<br>" +
          "<b>Nearest Relative Phone : </b>" +
          this.state.formObj.relativePhone +
          "<br>" +
          "<b>Nearest Relative Relationship : </b>" +
          this.state.formObj.relativeRelation +
          "<br>" +
          "<br>" +
          "<h5>Employment</h5>" +
          "<hr>" +
          "<b>Employer Name : </b>" +
          this.state.formObj.empName +
          "<br>" +
          "<b>Type of Work/Job Duties : </b>" +
          this.state.formObj.duties +
          "<br>" +
          "<b>Employment Start Date (MM/YYYY) : </b>" +
          Moment(GetDateFromMMYYYY(this.state.formObj.empStartDate)).format(
            "MM/YYYY"
          ) +
          "<br>" +
          "<b>Employer Phone Number : </b>" +
          this.state.formObj.empPhoneNo +
          "<br>" +
          "<b>Monthly Net Income(from all sources) : </b>" +
          parseInt(this.state.formObj.netIncome) +
          "<br>" +
          "<br>" +
          "<h5>Identification</h5>" +
          "<hr>" +
          "<b>Primary ID Type : </b>" +
          (parseInt(this.state.formObj.primaryIdType) > 0
            ? this.state.formObj.lstPrimaryIdType.find(
                (obj) => obj.id == this.state.formObj.primaryIdType
              )?.description
            : "") +
          "<br>" +
          "<b>Primary ID Number : </b>XXX-XX-" +
          this.state.formObj.primaryIdNo.slice(-3) +
          "<br>" +
          "<b>Primary ID State : </b>" +
          (parseInt(this.state.formObj.primaryIdState) > 0
            ? this.state.formObj.lstPrimaryIdState.find(
                (obj) => obj.id == this.state.formObj.primaryIdState
              )?.description
            : "") +
          "<br>" +
          "<table>" +
          "<tr>" +
          "<td>               </td>" +
          "<td>               </td>" +
          "<td>               </td>" +
          "<td><br>" +
          new Date().toLocaleString("en-US", {
            timeZone: "America/Los_Angeles",
          }) +
          "</td>" +
          "</tr>" +
          "</table>",
        poBox: "",
        bankAccountTypeId: 0,
        bankAccountTypeId_Code: "",
        debitCardBin: "",
        debitCardExpDate: "",
        downPayment: this.state.formObj.downPayment,
        isTransactionSuccessful : this.state.formObj.downPaymentStatus,
        downPayTransKey : "",
      };
      let obj2 = {
        SessionId: "0",
      };
      let result ={};
      await trackPromise(
        this.props.actions
          .creditSubmit(obj1, obj2)
          .then((response) => {
            if (response) {
              result = response;
              this.setState({ isAPIError: false, isAPIErrorMsg: "" });
              if (response.errorMessage !== null) {
                this.setState({ isAPIError: true });
                let error_ssn = response.errorMessage.replace(
                  "Applicant.SSN",
                  "SSN"
                );
                let error_HomePhone = error_ssn.replace(
                  "Applicant.HomePhone",
                  "Mobile Number"
                );
                let error_HomePhone_1 = error_HomePhone.replace(
                  "Applicant.CellPhone",
                  "Secondary Phone "
                );
                this.setState({
                  isAPIErrorMsg:
                    this.state.isAPIErrorMsg.concat(error_HomePhone_1),
                });
                this.setState({isStep6Complete:true, step7:true, showAppInProgress:!this.state.showAppInProgress});
              } else {
                // let appsettings = JSON.parse(
                //   localStorage.getItem("appsettings")
                // );
                // let djoURL = "";
                // if (!appsettings) {
                //   axios.get("/appsettings.json").then((response) => {
                //     appsettings = response.data;
                //     djoURL = appsettings.links.djourl;
                //   });
                // } else {
                //   djoURL = appsettings.links.djourl;
                // }
                // const winmessage = JSON.stringify({
                //   applicationId: response.credit.id,
                //   primaryId: this.state.formObj.primaryIdNo,
                //   url: djoURL,
                // });
                // window.parent.postMessage(winmessage, "*");
                // window.close();
                const winmessage = JSON.stringify({
                  applicationStatus : this.state.formObj.downPaymentStatus ? "Submitted" : "Canceled",
                });
                if(response?.application_id !== undefined && response?.application_id !== "null" && response?.application_id !== null) {
                  app_Id=response?.application_id
                }
                window.parent.postMessage(winmessage, "*");
                document.getElementById("txtDob").disabled = true;
                document.getElementById("txtSSN").disabled = true;
                document.getElementById("confirmStep1").hidden = true;
                this.setState({showAppInProgress:!this.state.showAppInProgress});
              }
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
      if(result.errorMessage === null || result.errorMessage === "null") {
        let result = await this.sendDataToShopify("submit",app_Id);
        if(!result.success) {
          toast.error(result.errorMessage?.replace('.','')+" from Shopify.")
        }
        let shopifyRedirectionURL = result.shopifyRedirectionURL;
        this.setState({
          showSubmittedAlert: !this.state.showSubmittedAlert,
          showTempCode : false,
          step1: true,
          isStep1Complete: false,
          step2: false,
          toggled: false,
          isStep2Complete: false,
          step3: false,
          isStep3Complete: false,
          step4: false,
          isStep4Complete: false,
          step5: false,
          isStep5Complete: false,
          step7: false,
          isStep7Complete: false,
          danielsDisclosure: false,
          showPopup: false,
          customerInfo: [],
          showSubmitConfirmation: false,
          isAgreed: false,
          djoSubmitted: true,
          formObj: {
            ...this.state.formObj,
            customerNo: "NEW",
            suffix: "0",
            firstName: "",
            middleName: "",
            lastName: "",
            mailingAdr: "",
            emailAdr: "",
            aptUnit: "",
            dob: "",
            city: "",
            mobileNo: "",
            state: "0",
            secPhone: "",
            zipCode: "",
            empName: "",
            empStartDate: "",
            empPhoneNo: "",
            netIncome: "",
            duties: "",
            housingInfo: "0",
            ssn: "",
            primaryIdType: "0",
            primaryIdNo: "",
            primaryIdState: "0",
            relativeName: "",
            relativePhone: "",
            relativeRelation: "",
            verificationCode: "",
            lstSuffix: [],
            lstState: [],
            lstHousingInfo: [],
            lstPrimaryIdType: [],
            lstPrimaryIdState: [],
          },
        },() => {
          if(shopifyRedirectionURL !== null)
            window.location.replace(shopifyRedirectionURL)
        });
    }
  }

  thirdPartySMSByPass(event) {
    event.preventDefault();
    this.setState({ showTempCode: !this.state.showTempCode });
    this.handleFormValidation("empty");
    this.txtVerificationCode.focus();
  }

  // cancelSession() {
  //   trackPromise(
  //     this.props.actions
  //       .cancelSessionLink(this.state.sessionId)
  //       .then((response) => {
  //         //this.setState({ autoPayModal: !this.state.autoPayModal });
  //         if (response.errorMessage === "Session Expired") {
  //           this.setState({
  //             step1: true,
  //             isStep1Complete: false,
  //             step2: false,
  //             toggled: false,
  //             isStep2Complete: false,
  //             step3: false,
  //             isStep3Complete: false,
  //             step4: false,
  //             isStep4Complete: false,
  //             step5: false,
  //             isStep5Complete: false,
  //             step7: false,
  //             isStep7Complete: false,
  //             danielsDisclosure: false,
  //             showPopup: false,
  //             customerInfo: [],
  //             showSubmitConfirmation: false,
  //             isAgreed: false,
  //           });
  //           this.setState({
  //             formObj: {
  //               ...this.state.formObj,
  //               customerNo: "NEW",
  //               suffix: "0",
  //               firstName: "",
  //               middleName: "",
  //               lastName: "",
  //               mailingAdr: "",
  //               emailAdr: "",
  //               aptUnit: "",
  //               dob: "",
  //               city: "",
  //               mobileNo: "",
  //               state: "0",
  //               secPhone: "",
  //               zipCode: "",
  //               empName: "",
  //               empStartDate: "",
  //               empPhoneNo: "",
  //               netIncome: "",
  //               duties: "",
  //               housingInfo: "0",
  //               ssn: "",
  //               primaryIdType: "0",
  //               primaryIdNo: "",
  //               primaryIdState: "0",
  //               relativeName: "",
  //               relativePhone: "",
  //               relativeRelation: "",
  //               verificationCode: "",
  //               lstSuffix: [],
  //               lstState: [],
  //               lstHousingInfo: [],
  //               lstPrimaryIdType: [],
  //               lstPrimaryIdState: [],
  //             },
  //           });
  //         }

  //         if (this.state.sessionId.length > 0) {
  //           const winmessage = JSON.stringify({
  //             applicationId: "0",
  //             primaryId: "0",
  //           });
  //           window.parent.postMessage(winmessage, "*");
  //           window.close();
  //         }
  //         window.location.replace("https://www.danielsjewelers.com/");
  //       })
  //       .catch((err) => {
  //         APIFailedMessage.displayError(err);
  //       })
  //   );
  // }

  // cancelSessionLink(event) {
  //   event.preventDefault();
  //   this.cancelSession();
  // }

  ErrorInSections() {
    if (!this.handleFormValidation("Step 1")) {
      this.setState({ errorInAccVerification: true });
    }
    if (!this.handleFormValidation("Step 3")) {
      this.setState({ errorInPersonalInfo: true });
    }
    if (!this.handleFormValidation("Step 4")) {
      this.setState({ errorInEmployment: true });
    }
    if (!this.handleFormValidation("Step 5")) {
      this.setState({ errorInIdentification: true });
    }
    if (!this.handleFormValidation("Step 6")) {
      this.setState({ errorInDanielsFields: true });
    }
    if (!this.handleFormValidation("Step 7")) {
      this.setState({ errorInDisclosure: true });
    }
  }

  handleFormValidationOnSubmit() {
    // event.preventDefault();
    if (this.state.step1 === false) {
      this.setState({ step1: true });
    }
    if (this.state.step2 === false) {
      this.setState({ step2: true });
    }
    if (this.state.step3 === false) {
      this.setState({ step3: true });
    }
    if (this.state.step4 === false) {
      this.setState({ step4: true });
    }
    if (this.state.step5 === false) {
      this.setState({ step5: true });
    }
    if (this.state.step6 === false) {
      this.setState({ step6: true });
    }
    this.setState({isStep6Complete:false, step7:false, showAppInProgress:!this.state.showAppInProgress},()=>{
      setTimeout(() => {
          this.handleValidation();
          }, this.state.timervalue); 
    });
  }

  handleValidation() {
    this.ErrorInSections();
    if (
      this.handleFormValidation("Step 1") &&
      this.handleFormValidation("Step 3") &&
      this.handleFormValidation("Step 4") &&
      this.handleFormValidation("Step 5") &&
      this.handleFormValidation("Step 6") &&
      this.handleFormValidation("Step 7")
    ) {
        this.setState({ showAppInProgress: !this.state.showAppInProgress });
        this.handleSubmitConfirmationModal("Submit");
    }
    else{
      this.setState({isStep6Complete:true, step7:true, showAppInProgress: !this.state.showAppInProgress });
    }
  }
  handleSubmitConfirmationModal(reqFrom) {
    if(reqFrom !== "Submit") {
      this.setState({isStep6Complete:true,step7:true});
    }
    this.setState({
      formObj : {...this.state.formObj, downPaymentStatus : !this.state.downPaymentStatus},
      showSubmitConfirmation: !this.state.showSubmitConfirmation,
    });
  }

  onDOBChange(event) {
    if (event === null) {
      this.setState({
        formObj: { ...this.state.formObj, dob: "" },
      });
    } else {
      this.setState({
        formObj: {
          ...this.state.formObj,
          dob: Moment(event).format("MM/DD/YYYY"),
        },
      });
    }
  }
  onStartDateChange(event) {
    if (event === null) {
      this.setState({
        formObj: { ...this.state.formObj, empStartDate: "" },
      });
    } else {
      this.setState({
        formObj: {
          ...this.state.formObj,
          empStartDate: Moment(event).format("MM/YYYY"),
        },
      });
    }
  }

  updateState(controlId, controlValue) {
    if (controlId === "txtMobileNo") {
      this.setState({
        formObj: {
          ...this.state.formObj,
          mobileNo: formatPhoneNumber(controlValue),
        },
      });
    }
    if (controlId === "txtFirstName") {
      controlValue = controlValue.replace(",", "");
      const re = /^(?!\s)[-a-zA-Z ]*$/;
      if (controlValue === "" || re.test(controlValue)) {
        this.setState({
          formObj: { ...this.state.formObj, firstName: controlValue },
        });
      }
    } else if (controlId === "txtMiddleName") {
      controlValue = controlValue.replace(",", "");
      const re = /^(?!\s)[-a-zA-Z ]*$/;
      if (controlValue === "" || re.test(controlValue)) {
        this.setState({
          formObj: { ...this.state.formObj, middleName: controlValue },
        });
      }
    } else if (controlId === "txtLastName") {
      controlValue = controlValue.replace(",", "");
      const re = /^(?!\s)[-a-zA-Z ]*$/;
      if (controlValue === "" || re.test(controlValue)) {
        this.setState({
          formObj: { ...this.state.formObj, lastName: controlValue },
        });
      }
    } else if (controlId === "cmbSuffix") {
      this.setState({
        formObj: { ...this.state.formObj, suffix: controlValue },
      });
    } else if (controlId === "txtSSN") {
      this.setState({
        formObj: { ...this.state.formObj, ssn: controlValue },
      });
    } else if (controlId === "txtSecPhone") {
      this.setState({
        formObj: {
          ...this.state.formObj,
          secPhone: formatPhoneNumber(controlValue),
        },
      });
    } else if (controlId === "txtEmailAdr") {
      this.setState({
        formObj: { ...this.state.formObj, emailAdr: controlValue },
      });
    } else if (controlId === "txtMailingAdr") {
      this.setState({
        formObj: { ...this.state.formObj, mailingAdr: controlValue },
      });
    } else if (controlId === "txtAptUnit") {
      this.setState({
        formObj: { ...this.state.formObj, aptUnit: controlValue },
      });
    } else if (controlId === "txtCity") {
      controlValue = controlValue.replace(",", "");
      const re = /^(?!\s)[-a-zA-Z ]*$/;
      if (controlValue === "" || re.test(controlValue)) {
        this.setState({
          formObj: { ...this.state.formObj, city: controlValue },
        });
      }
    } else if (controlId === "cmbState") {
      this.setState({
        formObj: { ...this.state.formObj, state: controlValue },
      });
    } else if (controlId === "txtZipCode") {
      const re = /^[0-9\b]+$/;
      if (controlValue === "" || re.test(controlValue)) {
        this.setState({
          formObj: { ...this.state.formObj, zipCode: controlValue },
        });
      }
    } else if (controlId === "cmbHousingInfo") {
      this.setState({
        formObj: { ...this.state.formObj, housingInfo: controlValue },
      });
    } else if (controlId === "txtRelativeName") {
      controlValue = controlValue.replace(",", "");
      const re = /^(?!\s)[-a-zA-Z ]*$/;
      if (controlValue === "" || re.test(controlValue)) {
        this.setState({
          formObj: { ...this.state.formObj, relativeName: controlValue },
        });
      }
    } else if (controlId === "txtRelativePhone") {
      this.setState({
        formObj: {
          ...this.state.formObj,
          relativePhone: formatPhoneNumber(controlValue),
        },
      });
    } else if (controlId === "txtRelativeRelation") {
      controlValue = controlValue.replace(",", "");
      const re = /^(?!\s)[-a-zA-Z ]*$/;
      if (controlValue === "" || re.test(controlValue)) {
        this.setState({
          formObj: { ...this.state.formObj, relativeRelation: controlValue },
        });
      }
    } else if (controlId === "txtEmpName") {
      // controlValue = controlValue.replace(",","");
      // const re = /^(?!\s)[-a-zA-Z ]*$/;
      // if (controlValue === '' || re.test(controlValue)) {
      //     this.setState({
      //         formObj: { ...this.state.formObj, empName: controlValue },
      //     });
      // }
      this.setState({
        formObj: { ...this.state.formObj, empName: controlValue },
      });
    } else if (controlId === "txtDuties") {
      controlValue = controlValue.replace(",", "");
      const re = /^(?!\s)[-a-zA-Z ]*$/;
      if (controlValue === "" || re.test(controlValue)) {
        this.setState({
          formObj: { ...this.state.formObj, duties: controlValue },
        });
      }
    } else if (controlId === "txtEmpPhoneNo") {
      this.setState({
        formObj: {
          ...this.state.formObj,
          empPhoneNo: formatPhoneNumber(controlValue),
        },
      });
    } else if (controlId === "txtNetIncome") {
      const re = /^[0-9\b]+$/;
      if (controlValue === "" || re.test(controlValue)) {
        this.setState({
          formObj: { ...this.state.formObj, netIncome: controlValue },
        });
      }
    } else if (controlId === "cmbPrimaryIdType") {
      this.setState({
        formObj: { ...this.state.formObj, primaryIdType: controlValue },
      });
    } else if (controlId === "txtPrimaryIdNo") {
      this.setState({
        formObj: { ...this.state.formObj, primaryIdNo: controlValue },
      });
    } else if (controlId === "cmbPrimaryIdState") {
      this.setState({
        formObj: { ...this.state.formObj, primaryIdState: controlValue },
      });
    } else if (controlId === "txtVerificationCode") {
      const re = /^[0-9\b]+$/;
      if (controlValue === "" || re.test(controlValue)) {
        this.setState({
          formObj: { ...this.state.formObj, verificationCode: controlValue },
        });
      }
    } else if (controlId === "UECaptcha") {
      this.setState({ UECaptcha: controlValue });
    }
  }

  updateStateId(stateList) {
    let stateID = stateList?.find((val) => {
      return (
        val.description?.toLowerCase() ===
        this.state.formObj.state?.toLowerCase()
      );
    });
    if (stateID === undefined) {
      this.setState({
        formObj: {
          ...this.state.formObj,
          state: "0",
        },
      });
    } else {
      this.setState({
        formObj: {
          ...this.state.formObj,
          state: stateID.id,
        },
      });
    }
  }

  onSSNChange(value, mask) {
    this.setState({
      formObj: { ...this.state.formObj, ssn: value },
    });
  }
  onChange(event) {
    this.setState({isStep7Complete : false , step8 : false})
    this.updateState(event.target.id, event.target.value);
  }
  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  loadData(data) {
    const winmessage = JSON.stringify({
      applicationId: data.lastDraftCreditId,
      primaryId: data.idNumber,
    });
    window.parent.postMessage(winmessage, "*");
    window.close();
    window.location.replace("https://www.danielsjewelers.com/");
    //localStorage.setItem("associateId", data.id);
    //this.setState({
    //    formObj: {
    //        ...this.state.formObj,
    //        credit_Id: data.LastDraftCreditId,
    //        customerNo: data.id,
    //        suffix: (data.suffix_Id === null ? "0" : data.suffix_Id),
    //        firstName: (data.firstName === null ? "" : data.firstName.replace(",","")),
    //        middleName: (data.middleName === null ? "" : data.middleName.replace(",","")),
    //        lastName: (data.lastName === null ? "" : data.lastName.replace(",","")),
    //        mailingAdr: (data.address === null ? "" : data.address),
    //        emailAdr: (data.emailAddress === null ? "" : data.emailAddress),
    //        aptUnit: (data.apt === null ? "" : data.apt),
    //        dob: (data.birthDate === null ? "" : Moment(data.birthDate).format("MM/DD/YYYY")),
    //        city: (data.city === null ? "" : data.city.replace(",","")),
    //        mobileNo: (data.mobileNo === null ? "" : formatPhoneNumber(data.mobileNo)),
    //        state: (data.state_Id === null ? "0" : data.state_Id),
    //        secPhone: (data.secondaryPhone === null ? "" : formatPhoneNumber(data.secondaryPhone)),
    //        zipCode: (data.zipCode === null ? "" : data.zipCode),
    //        empName: (data.empName === null ? "" : data.empName),
    //        empStartDate: (data.howLong === null ? "" : Moment(data.howLong).format("MM/YYYY")),
    //        empPhoneNo: (data.empPhone === null ? "" : formatPhoneNumber(data.empPhone)),
    //        netIncome: (data.monthlyIncome === null ? "" : data.monthlyIncome),
    //        duties: (data.workJob === null ? "" : data.workJob.replace(",","")),
    //        housingInfo: (data.housingInfo_Id === null ? "0" : data.housingInfo_Id),
    //        ssn: (data.ssn === null ? "" : data.ssn),
    //        primaryIdType: (data.primaryType_Id === null ? "0" : data.primaryType_Id),
    //        primaryIdNo: (data.idNumber === null ? "" : data.idNumber),
    //        primaryIdState: (data.primaryState_Id === null ? "0" : data.primaryState_Id),
    //        relativeName: (data.relativeName === null ? "" : data.relativeName.replace(",","")),
    //        relativePhone: (data.relativePhone === null ? "" : formatPhoneNumber(data.relativePhone)),
    //        relativeRelation: (data.relativeRelationship === null ? "" : data.relativeRelationship.replace(",","")),
    //        oftenPaid: (data.howOftenPaid_Id === null ? "0" : data.howOftenPaid_Id),
    //        cc: (data.creditCardBin === null ? "" : data.creditCardBin),
    //        lastPayDay: (data.lastPayDay === null ? "" : Moment(data.lastPayDay).format("MM/DD/YYYY")),
    //        ccExpiryDate: (data.creditCardExpDate === null ? "" : Moment(data.creditCardExpDate).format("MM/YYYY")),
    //        nextPayDay: (data.nextPayDay === null ? "" : Moment(data.nextPayDay).format("MM/DD/YYYY")),
    //        ccName: (data.nameOnCreditCard === null ? "" : data.nameOnCreditCard.replace(",","")),
    //        creditCardBillingAddress: data.creditCardHomeAddress,
    //        ccmailingAdr: (data.ccAddress === null ? "" : data.ccAddress),
    //        ccaptUnit: (data.ccApt === null ? "" : data.ccApt),
    //        cccity: (data.ccCity === null ? "" : data.ccCity.replace(",","")),
    //        ccstate: (data.ccState_Id === null ? "0" : data.ccState_Id),
    //        cczipCode: (data.ccZipCode === null ? "" : data.ccZipCode),
    //        bankRouteNo: (data.bankRoutingNo === null ? "" : data.bankRoutingNo),
    //        checkAccNo: (data.checkingAccountNo === null ? "" : data.checkingAccountNo),
    //        primaryIdExpiryDate: (data.primaryIdExpDate === null ? "" : Moment(data.primaryIdExpDate).format("MM/YYYY")),
    //        secondIdType: (data.secondIdType_Id === null ? "0" : data.secondIdType_Id),
    //        secondIdIssuedBy: (data.secondIdIssuedBy_Id === null ? "" : data.secondIdIssuedBy_Id),
    //        secondIdExpiryDate: (data.secondIdExpDate === null ? "" : Moment(data.secondIdExpDate).format("MM/YYYY")),
    //    }, verificationBox :false})
  }

  // duplicateDJOApplication(type) {
  //     if(type === "verify") {
  //         this.setState({djoIdError : "Searching. Please wait..."});
  //         this.setState({djoIdConfirm : false, verificationBox : true});
  //         if(this.state.djoId?.toLowerCase() === this.state.customerInfo?.customer?.idNumber?.toLowerCase()) {
  //             this.loadData(this.state.customerInfo.customer);
  //         }
  //         else {
  //             let obj ={
  //                 "phone": "",
  //                 "name": "",
  //                 "address": "",
  //                 "homeStore": "",
  //                 "accountId": "",
  //                 "id": this.state.djoId,
  //                 "ssn": "",
  //                 "balanceDue": "",
  //                 "lastActivity": "",
  //                 "email": "",
  //                 "session": this.state.sessionId,
  //                 "pageIndex": 0,
  //                 "pageSize": 0,
  //                 "sortBy": "",
  //                 "sortOrder": ""
  //             };
  //             trackPromise(
  //                 this.props.actions.djoMatchFoundValidate(obj).then(
  //                     response => {
  //                         if(response.length == 0) {
  //                             this.setState({djoIdError : "ID does not match"});
  //                         }
  //                         else {
  //                             this.loadData(response[0]);
  //                         }
  //                     }
  //                 ).catch(err => {
  //                     APIFailedMessage.displayError(err);
  //                 })
  //             )
  //         }
  //     }
  //     else if(type === "continue"){
  //         this.setState({
  //             formObj: {
  //                 ...this.state.formObj,
  //                 firstName: (this.state.customerInfo.data.firstname === null ? "" : this.state.customerInfo.data.firstname),
  //                 middleName: (this.state.customerInfo.data.middleName === null ? "" : this.state.customerInfo.data.middleName),
  //                 lastName: (this.state.customerInfo.data.lastName === null ? "" : this.state.customerInfo.data.lastName),
  //                 mailingAdr: (this.state.customerInfo.data.address === null ? "" : this.state.customerInfo.data.address),
  //                 emailAdr: (this.state.customerInfo.data.emailAddress === null ? "" : this.state.customerInfo.data.emailAddress),
  //                 aptUnit: (this.state.customerInfo.data.apt === null ? "" : this.state.customerInfo.data.apt),
  //                 city: (this.state.customerInfo.data.city === null ? "" : this.state.customerInfo.data.city),
  //                 mobileNo: (this.state.customerInfo.data.mobileNumber === null ? "" : formatPhoneNumber(this.state.customerInfo.data.mobileNumber)),
  //                 zipCode: (this.state.customerInfo.data.zipcode === null ? "" : this.state.customerInfo.data.zipcode),
  //                 state: this.state.customerInfo?.data.state?.trim(),
  //         },thirdPartySessionLink: true, cartId: this.state.customerInfo?.data.cartId, sessionId: this.state.customerInfo?.sessionId, isAutoPayRequired: this.state.customerInfo?.data.isAutoPay, verificationBox: false})
  //     }
  // }


  //The component start render from here
  async componentDidMount() {
    //Validating weather the URL contain "sessionId" query parameter or not
      //First loading appsetting.json file to know the base URL
      await axios.get("/appsettings.json").then((response) => {
        let appsettings = response.data;
        //storing appSetting.json in sessionStorage
        localStorage.setItem("shopify_appsettings", JSON.stringify(appsettings));
        //storing appSetting.json in redux
        // store.dispatch({type : ActionType.STORE_APPSETTING_FILE , payload : JSON.stringify(appsettings)});
        this.setState({ siteKey: appsettings.Keys.siteKey });
      });
      await trackPromise(
        //Making API call to validate the unique sessionId 
        this.props.action.generateToken("001").then((response) => {
          if(response) {
            if (response?.token !== null) {
              //storing token in redux for further API call
              // store.dispatch({type : ActionType.STORE_TOKEN, payload : response.token});
              localStorage.setItem("token", response.token);
              this.loadDropDowns("Suffix");
              this.loadDropDowns("State_Credit");
              this.loadDropDowns("HousingInfo");
              this.loadDropDowns("PrimaryIdType");
              this.loadDropDowns("State");
              // this.setState({showCongratulationModal : true});
            } else window.location.replace("https://www.danielsjewelers.com/");
          } else window.location.replace("https://www.danielsjewelers.com/");
        })
      ).catch((err) => {
        APIFailedMessage.displayError(err);
      });
      await this.props.actions
        .adminSettings()
        .then((res) => {
          if(res) {
            this.setState({isPaymentEnable : res.isPaymentEnable, timervalue : res.timervalue});
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        });
      await this.props.actions
        .getIpAddress()
        .then((res) => {
          if(res) {
            sessionStorage.setItem("ipAddress", res);
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        });
  }

  handleOnClick() {
    if (!this.handleFormValidation("Step 1")) {
      this.setState({ errorInAccVerification: true });
    }
    if (!this.handleFormValidation("Step 3")) {
      this.setState({ errorInPersonalInfo: true });
    }
    if (!this.handleFormValidation("Step 4")) {
      this.setState({ errorInEmployment: true });
    }
    if (!this.handleFormValidation("Step 5")) {
      this.setState({ errorInIdentification: true });
    }
    if (!this.handleFormValidation("Step 6")) {
      this.setState({ errorInDanielsFields: true });
    }
    if (
      this.handleFormValidation("Step 1") &&
      this.handleFormValidation("Step 3") &&
      this.handleFormValidation("Step 4") &&
      this.handleFormValidation("Step 5") &&
      this.handleFormValidation("Step 6") &&
      this.handleFormValidation("Step 7")
    ) {
      const script = document.createElement('script');
      script.src = "https://www.google.com/recaptcha/api.js?render="+JSON.parse(localStorage.getItem("shopify_appsettings"))?.Keys?.siteKey;
      script.addEventListener('load',() => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(JSON.parse(localStorage.getItem("shopify_appsettings"))?.Keys?.siteKey, { action: 'submit' }).then(token => {
            let obj={
              response : token
            }
            trackPromise(
              this.props.actions.validateCaptcha(obj).then((response) => {
                if(response) {
                  if(response?.score > 0.5 )
                    this.handleFormValidationOnSubmit();
                  else
                    toast.error("Captcha validation failed, please clear the cache and try again.");
                }
                else
                  APIFailedMessage.displayError("err");
              }).catch((err) => {
                APIFailedMessage.displayError(err);
              })
            )
          });
        });
      })
      document.body.appendChild(script);
    }
  }

  loadAutoPay(searchObj) {
    // trackPromise(
      this.props.actions
        .loadAutoPay(searchObj)
        .then((response) => {
          if (response) {
            this.setState({
              isAutoPayIframeError: false,
              isAutoPayIframeErrorMsg: "",
              autoPayIframeUrl: response.iframeUrl,
              custIdEnt: response.custIdEnt,
            });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    // );
  }

  loadDownPayment(searchObj) {
    // trackPromise(
      this.props.actions
        .loadDownPayment(searchObj)
        .then((response) => {
          if (response) {
            this.setState({
              isDownPaymentIframeError: false,
              isDownPaymentIframeErrorMsg: "",
              downPaymentIframeUrl: response.iframeUrl,
            });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    // );
  }

  loadDropDowns(type) {
    let obj = {
      type: type,
    };
    trackPromise(
      this.props.actions
        .getCodeMaster(obj)
        .then((response) => {
          if (response) {
            if (type === "Suffix") {
              this.setState({
                formObj: { ...this.state.formObj, lstSuffix: response.data },
              });
            } else if (type === "State_Credit") {
              let limitedState = [
                "georgia",
                "new york",
                "north carolina",
                "south carolina",
                "virginia",
                "ohio",
                "pennsylvania",
                "michigan",
              ];
              let data = response?.data?.filter((val) => {
                return !limitedState?.includes(
                  val?.description?.toLowerCase()
                );
              });
              this.setState({
                formObj: { ...this.state.formObj, lstState: data },
              });
              // if(this.state.formObj?.mailingAdr !== "") {
              //     this.loadAddress(this.state.formObj?.mailingAdr);
              //     this.autoFill(this.state.formObj?.mailingAdr);
              // } else {
              //     this.loadAddress('10');
              // }
            } else if (type === "HousingInfo") {
              this.setState({
                formObj: {
                  ...this.state.formObj,
                  lstHousingInfo: response.data,
                },
              });
            } else if (type === "PrimaryIdType") {
              this.setState({
                formObj: {
                  ...this.state.formObj,
                  lstPrimaryIdType: response.data,
                },
              });
            } else if (type === "State") {
              this.setState({
                formObj: {
                  ...this.state.formObj,
                  lstPrimaryIdState: response.data,
                },
              });
            }
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  }

  // loadAddress(obj) {
  //     this.handleFormValidation("revertDJOaddressVerify");
  //     if(obj === "") {
  //         obj="10";
  //         this.setState({formObj : {...this.state.formObj, mailingAdr : "", city : "", zipCode : "", state : '0' }});
  //     }
  //         this.props.actions.getAddress(obj).then(
  //             response => {
  //                 if(response) {
  //                     let array = response.Results?.map((val) => {
  //                         return val.Address;
  //                     });
  //                     this.setState({allAddress : array});
  //                 }
  //             }
  //         ).catch(err => {
  //             APIFailedMessage.displayError(err);
  //         })
  // }
  // autoFill(val) {
  //     let mailingAddress = val;
  //     let data=this.state.allAddress?.find((val) => val.AddressKey == mailingAddress);
  //     if((sessionStorage.getItem("SMSLink") == "true") || (this.state.thirdPartySessionLink ? (this.state.cartId == "" || this.state.cartId == "0") : false)) {
  //         let state = this.state.formObj?.lstState.find((val) => val.code == data?.State);
  //         if(state === undefined) {
  //             this.setState({formObj : {...this.state.formObj, mailingAdr : "", city : "", zipCode : "", state : '0' }});
  //             this.handleFormValidation("DJOaddressVerify");
  //         }
  //         else {
  //             this.setState({formObj : {...this.state.formObj, mailingAdr : data?.AddressLine1, city : data?.City, zipCode : data?.PostalCode.split("-")[0], state : state?.id }});
  //         }
  //     }
  //     else {
  //         let state = this.state.formObj?.lstState.find((val) => val.code == data.State);
  //         this.setState({formObj : {...this.state.formObj, mailingAdr : data?.AddressLine1, city : data?.City, zipCode : data?.PostalCode.split("-")[0], state : state?.id }});
  //     }
  // }

  handleContinue = () => {
    this.setState({showCongratulationModal : false, offerAccepted : true});
  }

  handleCancel = async() => {
    const winmessage = JSON.stringify({
      applicationStatus : this.state.formObj.downPaymentStatus ? "Submitted" : "Canceled",
    });
    window.parent.postMessage(winmessage, "*");
    let result = await this.sendDataToShopify("cancel","0");
    if(!result.success) {
      toast.error(result.errorMessage?.replace('.','')+" from Shopify.")
    }
    let shopifyRedirectionURL = result.shopifyRedirectionURL;
    document.getElementById("txtDob").disabled = true;
    document.getElementById("txtSSN").disabled = true;
    document.getElementById("confirmStep1").hidden = true;
    this.setState({
      showCongratulationModal: false,
      showTempCode : false,
      step1: true,
      isStep1Complete: false,
      step2: false,
      toggled: false,
      isStep2Complete: false,
      step3: false,
      isStep3Complete: false,
      step4: false,
      isStep4Complete: false,
      step5: false,
      isStep5Complete: false,
      step7: false,
      isStep7Complete: false,
      danielsDisclosure: false,
      showPopup: false,
      customerInfo: [],
      showSubmitConfirmation: false,
      isAgreed: false,
      djoSubmitted: true,
      offerAccepted : false,
      formObj: {
        ...this.state.formObj,
        customerNo: "NEW",
        suffix: "0",
        firstName: "",
        middleName: "",
        lastName: "",
        mailingAdr: "",
        emailAdr: "",
        aptUnit: "",
        dob: "",
        city: "",
        mobileNo: "",
        state: "0",
        secPhone: "",
        zipCode: "",
        empName: "",
        empStartDate: "",
        empPhoneNo: "",
        netIncome: "",
        duties: "",
        housingInfo: "0",
        ssn: "",
        primaryIdType: "0",
        primaryIdNo: "",
        primaryIdState: "0",
        relativeName: "",
        relativePhone: "",
        relativeRelation: "",
        verificationCode: "",
        lstSuffix: [],
        lstState: [],
        lstHousingInfo: [],
        lstPrimaryIdType: [],
        lstPrimaryIdState: [],
        totalPurchaseAmt: "",
        downPayment: "",
        downPaymentStatus : false,
        paymentPerMonth : "",
      },
    },() => {
      if(shopifyRedirectionURL !== null)
        window.location.replace(shopifyRedirectionURL)
    });
  }

  render() {
    const {
      danielsDisclosureErr,
      firstNameErr,
      lastNameErr,
      mailingAdrErr,
      emailAdrErr,
      aptUnitErr,
      dobErr,
      cityErr,
      mobileNoErr,
      stateErr,
      zipCodeErr,
      empNameErr,
      empStartDateErr,
      empPhoneNoErr,
      netIncomeErr,
      dutiesErr,
      housingInfoErr,
      ssnErr,
      primaryIdTypeErr,
      primaryIdNoErr,
      primaryIdStateErr,
      relativeNameErr,
      relativePhoneErr,
      relativeRelationErr,
      verificationCodeErr,
      secPhoneErr,
    } = this.state.formErrors;
    return (
      <>
        <ToastContainer className="p-3 position-fixed" position="top-end">
          <Toast
            bg="danger"
            onClose={() => {
              this.setState({
                errorInAccVerification: !this.state.errorInAccVerification,
              });
            }}
            show={this.state.errorInAccVerification}
            delay={5000}
            animation
            autohide
          >
            <Toast.Body className="text-white ml-2 mr-2">
              Invalid data in <b>Customer Account Verification</b> section.
            </Toast.Body>
          </Toast>
          <Toast
            bg="danger"
            onClose={() => {
              this.setState({
                errorInPersonalInfo: !this.state.errorInPersonalInfo,
              });
            }}
            show={this.state.errorInPersonalInfo}
            delay={5000}
            animation
            autohide
          >
            <Toast.Body className="text-white ml-2 mr-2">
              Invalid data in <b>Personal Information</b> section.
            </Toast.Body>
          </Toast>
          <Toast
            bg="danger"
            onClose={() => {
              this.setState({
                errorInEmployment: !this.state.errorInEmployment,
              });
            }}
            show={this.state.errorInEmployment}
            delay={5000}
            animation
            autohide
          >
            <Toast.Body className="text-white ml-2 mr-2">
              Invalid data in <b>Employment</b> section.
            </Toast.Body>
          </Toast>
          <Toast
            bg="danger"
            onClose={() => {
              this.setState({
                errorInIdentification: !this.state.errorInIdentification,
              });
            }}
            show={this.state.errorInIdentification}
            delay={5000}
            animation
            autohide
          >
            <Toast.Body className="text-white ml-2 mr-2">
              Invalid data in <b>Identification</b> section.
            </Toast.Body>
          </Toast>
          <Toast
            bg="danger"
            onClose={() => {
              this.setState({ errorInDanielsFields: !this.state.errorInDanielsFields });
            }}
            show={this.state.errorInDanielsFields}
            delay={5000}
            animation
            autohide
          >
            <Toast.Body className="text-white ml-2 mr-2">
              Invalid data in <b>Daniel's Credit Required Fields</b> section.
            </Toast.Body>
          </Toast>
          <Toast
            bg="danger"
            onClose={() => {
              this.setState({
                errorInDisclosure: !this.state.errorInDisclosure,
              });
            }}
            show={this.state.errorInDisclosure}
            delay={5000}
            animation
            autohide
          >
            <Toast.Body className="text-white ml-2 mr-2">
              Required data in <b>Disclosure</b> section.
            </Toast.Body>
          </Toast>
        </ToastContainer>
        <AugmentesIdleTime />
        <div className={`app ${this.state.toggled ? "toggled" : ""}`}>
          <main>
            <nav className="navbar bg-white justify-content-start">
              <img
                src={danielsLogo}
                alt="danielsLogo"
                className="img-fluid ml-4 mt-3 mb-3"
                width="100"
              ></img>
            </nav>
            <div id="main" className="container-fluid">
              <div className="row">
                <Card className="bg-white m-3 mt-3 mb-3 border-none w-100">
                  <Card.Title className="ml-3">
                    <p className="h4 p-0 pl-md-3 pr-md-3 mt-3">
                      <b>Daniel's Jewelers</b>
                    </p>
                    <p className="h4 p-0 pl-md-3 pr-md-3 ">
                      <b>Credit Application Form</b>
                    </p>
                  </Card.Title>
                  <Card.Body>
                    <p className="m-0 ml-md-3 mr-md-3 text-center mt-4 pt-2 p-2 customtext-secondary custombg-primary">
                      Please note that you must reside in the State of Arizona, California, Florida, Nevada, New Mexico, Texas and be 18 years or older to apply for Daniel's card.
                    </p>
                    <p className="m-0 ml-md-3 mr-md-3 text-right mt-4 pt-2 p-2 text-danger">
                      Note : All fields are required unless noted as optional.
                    </p>
                    <div className="row m-1 ml-md-0 mr-md-0 mb-md-0 mt-4">
                      <div className="col-12 p-0 pl-md-3 pr-md-3 ">
                        <hr
                          className="custombg-secondary mt-5 mb-0"
                          style={{ height: "1px" }}
                        ></hr>
                        <span
                          className="position-relative m-0 pr-3 customtext-secondary bg-white h5"
                          style={{ top: "-15px" }}
                        >
                          <b>Customer Account Verification</b>
                          {this.state.step1 === false ? (
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep1(event);
                              }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faAngleUp}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep1(event);
                              }}
                            />
                          )}
                        </span>
                        {this.state.step1 === true ? (
                          <div>
                            <div className="row">
                            <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Date of Birth (MM/DD/YYYY)
                                  </label>
                                  <DatePicker
                                    className={
                                      "form-control" +
                                      (dobErr
                                        ? " border-dark-error"
                                        : " border-dark")
                                    }
                                    name="txtDob"
                                    id="txtDob"
                                    placeholderText="Date of Birth (MM/DD/YYYY)"
                                    selected={
                                      this.state.formObj.dob === ""
                                        ? null
                                        : new Date(this.state.formObj.dob)
                                    }
                                    onChange={(event) =>
                                      this.onDOBChange(event)
                                    }
                                    dateFormat="MM/dd/yyyy"
                                    peekNextMonth
                                    maxDate={new Date()}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    customInput={
                                      <IMaskInput mask="00/00/0000" />
                                    }
                                  ></DatePicker>
                                  {dobErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {dobErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Social Security Number/ITIN#
                                  </label>
                                  <IMaskInput
                                    mask="000-00-0000"
                                    className={
                                      "form-control" +
                                      (ssnErr
                                        ? " border-dark-error"
                                        : " border-dark")
                                    }
                                    name="txtSSN"
                                    id="txtSSN"
                                    placeholder="Social Security Number/ITIN"
                                    onAccept={(value, mask) =>
                                      this.onSSNChange(value, mask)
                                    }
                                    value={this.state.formObj.ssn}
                                  ></IMaskInput>
                                  {ssnErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {ssnErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-12">
                                <button
                                  className="float-right btn custombg-secondary customtext-primary pl-5 pr-5"
                                  name="confirmStep1"
                                  id="confirmStep1"
                                  onClick={(event) => {
                                    this.submitStep1(event);
                                  }}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row m-1 ml-md-0 mr-md-0 mb-md-0 mt-4">
                      <div className="col-12 p-0 pl-md-3 pr-md-3 ">
                        <hr
                          className="custombg-secondary mt-5 mb-0"
                          style={{ height: "1px" }}
                        ></hr>
                        <span
                          className="position-relative m-0 pr-3 customtext-secondary bg-white h5"
                          style={{ top: "-15px" }}
                        >
                          <b>Mobile Verification</b>
                          {this.state.step2 === false ? (
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep2(event);
                              }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faAngleUp}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep2(event);
                              }}
                            />
                          )}
                        </span>
                        {this.state.isStep1Complete === true &&
                        this.state.step2 === true ? (
                          <div>
                            <p className="customtext-secondary">
                              To begin, please provide a mobile phone to send
                              verification code. Enter received code to verify.
                            </p>

                            <div className="row">
                              <div className="col-12 col-md-6">
                                <div className="row">
                                  <div className="col-7 col-lg-6">
                                    <div className="form-group text-left mt-md-2">
                                      <label className="customtext-secondary">
                                        Mobile Number
                                      </label>
                                      <input
                                        type="text"
                                        className={
                                          "form-control" +
                                          (mobileNoErr
                                            ? " border-dark-error"
                                            : " border-dark")
                                        }
                                        name="txtMobileNo"
                                        id="txtMobileNo"
                                        placeholder="Mobile Number *"
                                        onChange={(event) =>
                                          this.onChange(event)
                                        }
                                        value={this.state.formObj.mobileNo}
                                        maxLength="12"
                                        disabled={
                                          this.state.isSMSOverride ||
                                          this.state.isSMSVerified
                                            ? true
                                            : false
                                        }
                                      ></input>

                                      {mobileNoErr && (
                                        <div
                                          style={{
                                            color: "red",
                                            paddingBottom: 10,
                                          }}
                                        >
                                          {mobileNoErr}
                                        </div>
                                      )}
                                      {this.state.isSMSError && (
                                        <div
                                          style={{
                                            color: "red",
                                            paddingBottom: 10,
                                          }}
                                        >
                                          {this.state.isSMSErrorMsg}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {!this.state.isStep2Complete && (
                                    <div className="col-5 col-lg-4">
                                      <div className="form-group text-left mt-md-2">
                                        <label className="customtext-secondary">
                                          &nbsp;
                                        </label>
                                        <button
                                          className="form-control border-dark custombg-secondary customtext-primary p-0"
                                          name="mobileNoForOTP"
                                          id=""
                                          onClick={(event) =>
                                            this.submitMobileNo(event)
                                          }
                                          disabled={!this.state.offerAccepted}
                                        >
                                          Send Code
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {(this.state.SMSCodeSent || this.state.isSMSError) &&
                                <div className="col-12 col-md-6">
                                  {!this.state.isStep2Complete && (
                                    <div className="row">
                                      <div className="col-7 col-lg-6">
                                        <div className="form-group text-left mt-md-2">
                                          <label className="customtext-secondary">
                                            Verification code
                                          </label>
                                          <input
                                            type="text"
                                            placeholder="Verification code"
                                            className={
                                              verificationCodeErr
                                                ? "form-control border-dark-error"
                                                : "form-control border-dark"
                                            }
                                            name="txtVerificationCode"
                                            id="txtVerificationCode"
                                            onChange={(event) =>
                                              this.onChange(event)
                                            }
                                            value={
                                              this.state.formObj.verificationCode
                                            }
                                            maxLength="6"
                                            ref={(input) => {
                                              this.txtVerificationCode = input;
                                            }}
                                          />
                                          {verificationCodeErr && (
                                            <div
                                              style={{
                                                color: "red",
                                                paddingBottom: 10,
                                              }}
                                            >
                                              {verificationCodeErr}
                                            </div>
                                          )}
                                          <div
                                            className={
                                              this.state.SMSCodeSent ||
                                              this.state.isSMSError
                                                ? ""
                                                : "d-none"
                                            }
                                          >
                                            <div
                                              className={
                                                this.state.showTempCode
                                                  ? "d-none"
                                                  : ""
                                              }
                                            >
                                              <label className="customtext-secondary">
                                                Didn’t receive your code?
                                                <u
                                                  style={{ cursor: "pointer" }}
                                                  onClick={(event) => {
                                                    this.thirdPartySMSByPass(
                                                      event
                                                    );
                                                  }}
                                                >
                                                  Click here
                                                </u>
                                              </label>
                                            </div>
                                            <div
                                              className={
                                                this.state.showTempCode
                                                  ? ""
                                                  : "d-none"
                                              }
                                            >
                                              <p className="customtext-secondary">
                                                Your temporary code is{" "}
                                                <b>010101</b>.
                                              </p>
                                              <p className="mb-0 text-danger">
                                                This application will be submitted
                                                but is considered incomplete until
                                                your mobile number is verified.
                                                Our credit department will be
                                                contacting you soon.
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-5 col-lg-3">
                                        <div className="form-group text-left mt-md-2">
                                          <label className="customtext-secondary">
                                            &nbsp;
                                          </label>
                                          <button
                                            name="confirmStep2"
                                            id="confirmStep2"
                                            className="form-control border-dark custombg-secondary customtext-primary"
                                            placeholder="Enter Code"
                                            onClick={(event) => {
                                              this.submitStep2(event);
                                            }}
                                            disabled={!this.state.offerAccepted}
                                          >
                                            Verify
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              }
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row m-1 ml-md-0 mr-md-0 mb-md-0 mt-4">
                      <div className="col-12 p-0 pl-md-3 pr-md-3 ">
                        <hr
                          className="custombg-secondary mt-5 mb-0"
                          style={{ height: "1px" }}
                        ></hr>
                        <span
                          className="position-relative m-0 pr-3 customtext-secondary bg-white h5"
                          style={{ top: "-15px" }}
                        >
                          <b>Personal Information</b>
                          {this.state.step3 === false ? (
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep3(event);
                              }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faAngleUp}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep3(event);
                              }}
                            />
                          )}
                        </span>
                        {this.state.isStep2Complete === true &&
                        this.state.step3 === true ? (
                          <div>
                            <div className="row">
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control" +
                                      (firstNameErr
                                        ? " border-dark-error"
                                        : " border-dark")
                                    }
                                    name="txtFirstName"
                                    id="txtFirstName"
                                    placeholder="First Name"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.firstName}
                                    maxLength="200"
                                  ></input>
                                  {firstNameErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {firstNameErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Middle Name (Optional)
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control border-dark"
                                    name="txtMiddleName"
                                    id="txtMiddleName"
                                    placeholder="Middle Name (Optional)"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.middleName}
                                    maxLength="200"
                                  ></input>
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control " +
                                      (lastNameErr
                                        ? " border-dark-error"
                                        : " border-dark")
                                    }
                                    name="txtLastName"
                                    id="txtLastName"
                                    placeholder="Last Name"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.lastName}
                                    maxLength="200"
                                  ></input>
                                  {lastNameErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {lastNameErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Suffix (Optional)
                                  </label>

                                  <select
                                    id="cmbSuffix"
                                    name="cmbSuffix"
                                    className="form-select p-2 border-dark"
                                    onChange={(event) => this.onChange(event)}
                                  >
                                    <option value="0">-- Select --</option>
                                    {this.state.formObj.lstSuffix.map(
                                      (suffix) => (
                                        <option
                                          value={suffix.id}
                                          selected={
                                            this.state.formObj.suffix ==
                                            suffix.id
                                              ? "selected"
                                              : ""
                                          }
                                        >
                                          {suffix.description}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Mailing Address
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control" +
                                      (mailingAdrErr
                                        ? " border-dark-error"
                                        : " border-dark")
                                    }
                                    name="txtMailingAdr"
                                    id="txtMailingAdr"
                                    placeholder="Mailing Address"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.mailingAdr}
                                    maxLength="500"
                                  ></input>
                                  {/* <Autocomplete
                                                                            items={this.state.allAddress}
                                                                            shouldItemRender={(item, value) => item?.AddressLine1?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1}
                                                                            getItemValue={item => item?.AddressKey}
                                                                            renderItem={(item, isHighlighted) =>
                                                                                <div style={{ background: isHighlighted ? '#007bff' : 'white', color : isHighlighted ? 'white' : '#1b1b1b', padding : "1px 5px" }}
                                                                                    key={item?.AddressKey}>
                                                                                    {item?.AddressLine1}
                                                                                </div>
                                                                            }
                                                                            wrapperProps={{ style : {width : '100%', display: 'inline-block'} }}
                                                                            menuStyle ={{
                                                                                borderRadius: '5px',
                                                                                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                                                                background: 'white',
                                                                                padding: '3px 3px',
                                                                                position: 'absolute',
                                                                                maxHeight: mailingAdrErr === undefined ? '200%' : '120%',
                                                                                overflow:'auto',
                                                                                zIndex : '100',
                                                                                top: mailingAdrErr === undefined ? '85%' : '55%',
                                                                                left: '4%',
                                                                              }}
                                                                            value={this.state.formObj.mailingAdr}
                                                                            onChange={e => {this.setState({formObj : {...this.state.formObj, mailingAdr : e.target.value}}); this.loadAddress(e.target.value)}}
                                                                            onSelect={(val) => {this.autoFill(val)}}
                                                                            inputProps={{
                                                                                placeholder: 'Mailing Address',
                                                                                className : "form-control border-dark autoAddressField"
                                                                            }}
                                                                        /> */}
                                  {mailingAdrErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {mailingAdrErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-2">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Apt/Unit (As Needed)
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control" +
                                      (aptUnitErr
                                        ? " border-dark-error"
                                        : " border-dark")
                                    }
                                    name="txtAptUnit"
                                    id="txtAptUnit"
                                    placeholder="Apt/Unit (As Needed)"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.aptUnit}
                                    maxLength="100"
                                  ></input>
                                  {aptUnitErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {aptUnitErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className={"col-12 col-md-6 col-lg-3"}>
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    City
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control" +
                                      (cityErr
                                        ? " border-dark-error"
                                        : " border-dark")
                                    }
                                    name="txtCity"
                                    id="txtCity"
                                    placeholder="City"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.city}
                                    maxLength="100"
                                  ></input>
                                  {cityErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {cityErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className={"col-12 col-md-6 col-lg-2"}>
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    State
                                  </label>
                                  <select
                                    id="cmbState"
                                    name="cmbState"
                                    className={
                                      "form-select p-2" +
                                      (stateErr
                                        ? " border-dark-error"
                                        : " form-select-border border-dark")
                                    }
                                    onChange={(event) => this.onChange(event)}
                                  >
                                    <option value="0">-- Select --</option>
                                    {this.state.formObj.lstState.map(
                                      (state) => (
                                        <option
                                          value={state.id}
                                          selected={
                                            this.state.formObj.state == state.id
                                              ? "selected"
                                              : ""
                                          }
                                        >
                                          {state.description}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  {stateErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {stateErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-2">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Zip Code
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control" +
                                      (zipCodeErr
                                        ? " border-dark-error"
                                        : " border-dark")
                                    }
                                    name="txtZipCode"
                                    id="txtZipCode"
                                    placeholder="Zip Code"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.zipCode}
                                    maxLength="5"
                                  ></input>
                                  {zipCodeErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {zipCodeErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Housing Information
                                  </label>
                                  <select
                                    id="cmbHousingInfo"
                                    name="cmbHousingInfo"
                                    className={
                                      "form-select p-2" +
                                      (housingInfoErr
                                        ? " border-dark-error"
                                        : " form-select-border borer-dark")
                                    }
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.housingInfo}
                                  >
                                    <option value="0">-- Select --</option>
                                    {this.state.formObj.lstHousingInfo.map(
                                      (housingInfo) => (
                                        <option value={housingInfo.id}>
                                          {housingInfo.description}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  {housingInfoErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {housingInfoErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Secondary Phone (Optional)
                                  </label>

                                  <input
                                    type="text"
                                    className={
                                      "form-control " +
                                      (secPhoneErr
                                        ? " border-dark-error"
                                        : " border-dark")
                                    }
                                    name="txtSecPhone"
                                    id="txtSecPhone"
                                    placeholder="Secondary Phone (Optional)"
                                    value={this.state.formObj.secPhone}
                                    onChange={(event) => this.onChange(event)}
                                    maxLength="12"
                                  ></input>
                                  {secPhoneErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {secPhoneErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Email Address
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control " +
                                      (emailAdrErr
                                        ? " border-dark-error"
                                        : " border-dark")
                                    }
                                    name="txtEmailAdr"
                                    id="txtEmailAdr"
                                    placeholder="Email Address"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.emailAdr}
                                    maxLength="100"
                                  ></input>
                                  {emailAdrErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {emailAdrErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-12">
                                <button
                                  className="float-right btn custombg-secondary customtext-primary pl-5 pr-5"
                                  name="confirmStep3"
                                  id="confirmStep3"
                                  onClick={(event) => {
                                    this.submitStep3(event);
                                  }}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row m-1 ml-md-0 mr-md-0 mb-md-0 mt-4">
                      <div className="col-12 p-0 pl-md-3 pr-md-3 ">
                        <hr
                          className="custombg-secondary mt-5 mb-0"
                          style={{ height: "1px" }}
                        ></hr>
                        <span
                          className="position-relative m-0 pr-3 customtext-secondary bg-white h5"
                          style={{ top: "-15px" }}
                        >
                          <b>Employment</b>
                          {this.state.step4 === false ? (
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep4(event);
                              }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faAngleUp}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep4(event);
                              }}
                            />
                          )}
                        </span>
                        {this.state.isStep3Complete === true &&
                        this.state.step4 === true ? (
                          <div>
                            <div className="row">
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Employer's Name
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control " +
                                      (empNameErr
                                        ? "border-dark-error"
                                        : "border-dark")
                                    }
                                    name="txtEmpName"
                                    id="txtEmpName"
                                    placeholder="Employer's Name"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.empName}
                                    maxLength="200"
                                  ></input>
                                  {empNameErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {empNameErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Type of Work/Job Duties
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control " +
                                      (dutiesErr
                                        ? "border-dark-error"
                                        : "border-dark")
                                    }
                                    name="txtDuties"
                                    id="txtDuties"
                                    placeholder="Type of Work/Job Duties"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.duties}
                                    maxLength="500"
                                  ></input>
                                  {dutiesErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {dutiesErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Employment Start Date (MM/YYYY)
                                  </label>
                                  <DatePicker
                                    className={
                                      "form-control " +
                                      (empStartDateErr
                                        ? "border-dark-error"
                                        : "border-dark")
                                    }
                                    name="txtEmpStartDate"
                                    id="txtEmpStartDate"
                                    placeholderText="Start Date (MM/YYYY)"
                                    selected={
                                      this.state.formObj.empStartDate === ""
                                        ? null
                                        : new Date(
                                            this.state.formObj.empStartDate.split(
                                              "/"
                                            )[1],
                                            parseInt(
                                              this.state.formObj.empStartDate.split(
                                                "/"
                                              )[0]
                                            ) - 1
                                          )
                                    }
                                    onChange={(event) =>
                                      this.onStartDateChange(event)
                                    }
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    maxDate={new Date()}
                                    customInput={<IMaskInput mask="00/0000" />}
                                  />
                                  {empStartDateErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {empStartDateErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2"></div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Employer's Phone Number
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control " +
                                      (empPhoneNoErr
                                        ? "border-dark-error"
                                        : "border-dark")
                                    }
                                    name="txtEmpPhoneNo"
                                    id="txtEmpPhoneNo"
                                    placeholder="Employer's Phone Number"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.empPhoneNo}
                                    maxLength="12"
                                  ></input>
                                  {empPhoneNoErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {empPhoneNoErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Monthly Net Income(from all sources)
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control " +
                                      (netIncomeErr
                                        ? "border-dark-error"
                                        : "border-dark")
                                    }
                                    name="txtNetIncome"
                                    id="txtNetIncome"
                                    placeholder="Monthly Net Income(from all sources) **"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.netIncome}
                                    maxLength="5"
                                  ></input>
                                  {netIncomeErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {netIncomeErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-12">
                                <button
                                  className="float-right btn custombg-secondary customtext-primary pl-5 pr-5"
                                  name="confirmStep4"
                                  id="confirmStep4"
                                  onClick={(event) => {
                                    this.submitStep4(event);
                                  }}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="row m-1 ml-md-0 mr-md-0 mb-md-0 mt-4">
                      <div className="col-12 p-0 pl-md-3 pr-md-3 ">
                        <hr
                          className="custombg-secondary mt-5 mb-0"
                          style={{ height: "1px" }}
                        ></hr>
                        <span
                          className="position-relative m-0 pr-3 customtext-secondary bg-white h5"
                          style={{ top: "-15px" }}
                        >
                          <b>Identification</b>
                          {this.state.step5 === false ? (
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep5(event);
                              }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faAngleUp}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep5(event);
                              }}
                            />
                          )}
                        </span>
                        {this.state.isStep4Complete === true &&
                        this.state.step5 === true ? (
                          <div>
                            <div className="row">
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Primary ID Type
                                  </label>
                                  <select
                                    id="cmbPrimaryIdType"
                                    name="cmbPrimaryIdType"
                                    className={
                                      "form-select p-2" +
                                      (primaryIdTypeErr
                                        ? " border-dark-error"
                                        : " form-select-border border-dark")
                                    }
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.primaryIdType}
                                  >
                                    <option value="0">-- Select --</option>
                                    {this.state.formObj.lstPrimaryIdType.map(
                                      (primaryIdType) => (
                                        <option value={primaryIdType.id}>
                                          {primaryIdType.description}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  {primaryIdTypeErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {primaryIdTypeErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Primary ID Number
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control " +
                                      (primaryIdNoErr
                                        ? "border-dark-error"
                                        : "border-dark")
                                    }
                                    name="txtPrimaryIdNo"
                                    id="txtPrimaryIdNo"
                                    placeholder="Primary ID Number"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.primaryIdNo}
                                    maxLength="50"
                                  ></input>
                                  {primaryIdNoErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {primaryIdNoErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Primary ID State
                                  </label>
                                  <select
                                    id="cmbPrimaryIdState"
                                    name="cmbPrimaryIdState"
                                    className={
                                      "form-select p-2" +
                                      (primaryIdStateErr
                                        ? " border-dark-error"
                                        : " form-select-border border-dark")
                                    }
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.primaryIdState}
                                  >
                                    <option value="0">-- Select --</option>
                                    {this.state.formObj.lstPrimaryIdState.map(
                                      (primaryIdState) => (
                                        <option value={primaryIdState.id}>
                                          {primaryIdState.description}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  {primaryIdStateErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {primaryIdStateErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-12">
                                <button
                                  className="float-right btn custombg-secondary customtext-primary pl-5 pr-5"
                                  name="confirmStep5"
                                  id="confirmStep5"
                                  onClick={(event) => {
                                    this.submitStep5(event);
                                  }}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row m-1 ml-md-0 mr-md-0 mb-md-0 mt-4">
                      <div className="col-12 p-0 pl-md-3 pr-md-3 ">
                        <hr
                          className="custombg-secondary mt-5 mb-0"
                          style={{ height: "1px" }}
                        ></hr>
                        <span
                          className="position-relative m-0 pr-3 customtext-secondary bg-white h5"
                          style={{ top: "-15px" }}
                        >
                          <b>Daniel's Credit Required Fields</b>
                          {this.state.step6 === false ? (
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep6(event);
                              }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faAngleUp}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep6(event);
                              }}
                            />
                          )}
                        </span>
                        {this.state.isStep5Complete === true &&
                        this.state.step6 === true ? (
                          <div>
                            <div className="row">
                            <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Nearest Relative Name
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control " +
                                      (relativeNameErr
                                        ? "border-dark-error"
                                        : "border-dark")
                                    }
                                    name="txtRelativeName"
                                    id="txtRelativeName"
                                    placeholder="Nearest Relative Name"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.relativeName}
                                    maxLength="200"
                                  ></input>
                                  {relativeNameErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {relativeNameErr}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Nearest Relative Phone
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control " +
                                      (relativePhoneErr
                                        ? "border-dark-error"
                                        : "border-dark")
                                    }
                                    name="txtRelativePhone"
                                    id="txtRelativePhone"
                                    placeholder="Nearest Relative Phone"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.relativePhone}
                                    maxLength="12"
                                  ></input>
                                  {relativePhoneErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {relativePhoneErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-3">
                                <div className="form-group text-left mt-md-2">
                                  <label className="customtext-secondary">
                                    Nearest Relative Relationship
                                  </label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control " +
                                      (relativeRelationErr
                                        ? "border-dark-error"
                                        : "border-dark")
                                    }
                                    name="txtRelativeRelation"
                                    id="txtRelativeRelation"
                                    placeholder="Nearest Relative Relationship"
                                    onChange={(event) => this.onChange(event)}
                                    value={this.state.formObj.relativeRelation}
                                    maxLength="200"
                                  ></input>
                                  {relativeRelationErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {relativeRelationErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-12">
                                <button
                                  className="float-right btn custombg-secondary customtext-primary pl-5 pr-5"
                                  name="confirmStep5"
                                  id="confirmStep5"
                                  onClick={(event) => {
                                    this.submitStep6(event);
                                  }}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* <div className={"row m-1 ml-md-0 mr-md-0 mb-md-0 mt-4"}>
                      <div className="col-12 p-0 pl-md-3 pr-md-3 ">
                        <hr
                          className="custombg-secondary mt-5 mb-0"
                          style={{ height: "1px" }}
                        ></hr>
                        <span
                          className="position-relative m-0 pr-3 customtext-secondary bg-white h5"
                          style={{ top: "-15px" }}
                        >
                          <b>Auto-Pay Enrollment</b>
                          {this.state.step6 === false ? (
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep6(event);
                              }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faAngleUp}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep6(event);
                              }}
                            />
                          )}
                        </span>
                        {this.state.isStep5Complete === true &&
                        this.state.step6 === true ? (
                          <div>
                            <div className={`row ${(this.state.cartId == "0" || this.state.cartId=="" ?"d-none":"")}` }>
                              <div className="col-12 col-md-6 col-lg-12">
                                <div className="form-group form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="chkAutoPay"
                                    id="chkAutoPay"
                                    onClick={() => this.onAutoPayChecked()}
                                    value={this.state.autoPay}
                                    checked={
                                      this.state.autoPay ? "checked" : ""
                                    }
                                    disabled={
                                      this.state.autoPaySubmitted
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    htmlFor="chkAutoPay"
                                    className="mt-1 ml-2"
                                  >
                                    YES! I'd like to enroll Auto-Pay
                                  </label>
                                  <br />
                                </div>
                                {autoPayErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                      }}
                                    >
                                      {autoPayErr}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="row">
                              {this.state.autoPaySubmitted && (
                                <div
                                  className="pl-3 pb-3"
                                  style={{ color: "green" }}
                                >
                                  Auto Pay Submitted Successfully
                                </div>
                              )}
                              <div
                                className={
                                  "col-12 col-md-12 col-lg-6" +
                                  (this.state.autoPay && !this.state.autoPaySubmitted
                                    ? " ShowContent"
                                    : " HideContent")
                                }
                              >
                                <iframe
                                  title="autoPayIframe"
                                  id="autoPayIframe"
                                  src={this.state.autoPayIframeUrl}
                                  height="450px"
                                  width="100%"
                                  style={{ border: "1px solid #1B1B1B" }}
                                ></iframe>
                                {this.state.isAutoPayIframeError && (
                                  <div
                                    style={{ color: "red", paddingBottom: 10 }}
                                  >
                                    {this.state.isAutoPayIframeErrorMsg}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col-12">
                                <button
                                  className="float-right btn custombg-secondary customtext-primary pl-5 pr-5"
                                  name="confirmStep6"
                                  id="confirmStep6"
                                  onClick={(event) => {
                                    this.submitStep6(event);
                                  }}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div> */}
                    <div className="row m-1 ml-md-0 mr-md-0 mb-md-0 mt-4">
                      <div className="col-12 p-0 pl-md-3 pr-md-3 ">
                        <hr
                          className="custombg-secondary mt-5 mb-0"
                          style={{ height: "1px" }}
                        ></hr>
                        <span
                          className="position-relative m-0 pr-3 customtext-secondary bg-white h5"
                          style={{ top: "-15px" }}
                        >
                          <b>Disclosures</b>
                          {this.state.step7 === false ? (
                            <FontAwesomeIcon
                              icon={faAngleDown}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep7(event);
                              }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faAngleUp}
                              className="float-right bg-white pl-3"
                              size="lg"
                              onClick={(event) => {
                                this.goStep7(event);
                              }}
                            />
                          )}
                        </span>
                        {this.state.isStep6Complete === true &&
                        this.state.step7 === true ? (
                          <div>
                            <div className="row">
                              <div className="col-12 customBorder shadow">
                                <div className="pl-3 pr-3 pl-md-0 pr-md-0 pl-lg-0 pr-lg-0">
                                  <p className="h5 mt-3">
                                    <b>DANIEL'S CREDIT APPLICANTS</b>
                                  </p>
                                  <p className="text-justify">
                                    By applying for this account, I am (we are)
                                    asking Daniel’s Jewelers (“Daniel’s”) to
                                    open a credit account for me (us) and by
                                    signing this agreement I (we) (A)
                                    acknowledge receipt of the Daniel’s Jewelers
                                    Preferred Charge Retail Installment
                                    Agreement Summary of Terms (RICA) and
                                    Privacy Policy (available at{" "}
                                    <a href="https://www.danielsjewelers.com/pages/privacy-policy" 
                                       rel="noreferrer" 
                                       target="_blank" 
                                       style={{ color: "rgb(0, 82, 204)" }}
                                    >
                                        www.DanielsJewelers.com/Pages/Privacy-Policy
                                    </a>
                                    ), and if approved for this account, to be
                                    bound by the full terms and conditions of
                                    the RICA, (B) authorize Daniel’s and
                                    affiliates to verify all credit, employment,
                                    references, or banking information, obtain
                                    current balances on any such accounts, and
                                    request information from credit bureau
                                    reporting agencies, and, if approved for
                                    this account, whether a purchase is charged
                                    to this account or not, authorize Daniel’s
                                    to re-verify, obtain updates on such
                                    accounts and to obtain information from and
                                    report the payment history and account
                                    status of this account to and from credit
                                    bureau reporting agencies without my (our)
                                    specific authorization or a specific request
                                    for purchase approval or credit line
                                    increase, (C) if a joint account is
                                    requested by providing information about and
                                    the signature of a second individual, agree
                                    that the primary and joint accountholder
                                    each can individually sign and incur
                                    obligations on behalf of the other, (D)
                                    understand and agree that I am (we are)
                                    submitting this application and all of the
                                    information on this application to both the
                                    designated entities that I (we) have signed
                                    for as well as their affiliates and
                                    designees for their use in marketing
                                    products and services to me as permitted by
                                    applicable law; I (we) authorize Daniel’s,
                                    its affiliates and its designees to submit
                                    and disclose this application to other
                                    lender(s) or lease-to-own providers which
                                    is/are not affiliated with any of the
                                    designated entities on this application, (E)
                                    authorize Daniel’s to contact me via mail,
                                    email, telephone, cellular telephone, text
                                    messaging (SMS or MMS), and any other
                                    electronic means, including, but not limited
                                    to through automatic telephone dialing
                                    systems and/or with artificial or
                                    prerecorded voices at, including, but not
                                    limited to, those contact methods noted with
                                    (+ or *) above, for account service,
                                    collections, sales promotion including
                                    marketing, and other consumer business
                                    related matters. I (we) assume the risk that
                                    electronic communications to us (text,
                                    email, voice message) may be viewed or
                                    accessed by others with access to our
                                    device(s). I (we) acknowledge that we may
                                    receive up to ten (10) calls and ten (10)
                                    text messages per month. I (we) acknowledge
                                    that, to opt out now or at any time from
                                    receiving calls or texts for any purpose
                                    other than accounts servicing or collection,
                                    I may call (310) 846-5630 or text “STOP” in
                                    response to any text message. I (we)
                                    acknowledge that standard text messaging and
                                    call use rates may apply and will be
                                    accepted by me (us) with no reimbursement or
                                    payment by Daniel’s; I (we) make to or
                                    receive from Daniel’s may be monitored or
                                    recorded by Daniel’s for supervisory
                                    purposes; I understand that consent for
                                    marketing is NOT a condition of purchasing
                                    or receiving service and I can opt out at
                                    any time, (F) understand that in order to
                                    discontinue receiving contact for any
                                    purpose other than accounts servicing or
                                    collection, I can call (310) 846-5630 during
                                    regular business hours.{" "}
                                  </p>
                                  <p className="text-center">
                                    <b>
                                      Please{" "}
                                      <u
                                        style={{
                                          color: "#00aeef",
                                          cursor: "pointer",
                                        }}
                                        onClick={(event) =>
                                          this.onDanielsDisclosureClicked(event)
                                        }
                                      >
                                        click here
                                      </u>{" "}
                                      for Daniel’s Jewelers disclosures and
                                      credit agreement.
                                    </b>
                                  </p>
                                  <p className="text-center mb-0">
                                    <b>NOTICE TO BUYER(S):</b>
                                  </p>
                                  <p className="text-center">
                                    <b>
                                      DO NOT SIGN THIS APPLICATION BEFORE
                                      READING THE DANIEL’S RETAIL INSTALLMENT
                                      CREDIT AGREEMENT OR IF IT CONTAINS BLANK
                                      SPACES.
                                    </b>
                                  </p>
                                  <p className={"text-center text-uppercase"}>
                                    <b>
                                      The Ohio laws against discrimination
                                      require that all creditors make credit
                                      equally available to all credit worthy
                                      customers. The Ohio Civil Rights
                                      Commission administers compliance with
                                      this law
                                    </b>
                                  </p>
                                  <div className="form-group form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="chkDanielsDisclosure"
                                      id="chkDanielsDisclosure"
                                      onChange={(event) => this.onChange(event)}
                                      onClick={() =>
                                        this.onDanielsDisclosureChecked()
                                      }
                                      value={this.state.danielsDisclosure}
                                      checked={
                                        this.state.danielsDisclosure
                                          ? "checked"
                                          : ""
                                      }
                                    />
                                    <label
                                      htmlFor="chkDanielsDisclosure"
                                      className="mt-1 ml-2"
                                    >
                                      I have read and agree to the Application
                                      Disclosure, Consent to Electronic
                                      Communication, Credit Agreement, and
                                      Privacy Policy. Selecting “Submit
                                      Application” below constitutes my
                                      electronic signature.
                                    </label>
                                    <br />
                                    {danielsDisclosureErr && (
                                      <div
                                        style={{
                                          color: "red",
                                          paddingBottom: 10,
                                        }}
                                      >
                                        {danielsDisclosureErr}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr
                              className="custombg-secondary ml-3 mr-3"
                              style={{ height: "1px" }}
                            ></hr>

                            {/* <div className="row ml-1 mb-4">
                              <div className="col-12 col-md-6 pl-0">
                                <p className="h3 mb-4 font-weight-bold">
                                  Captcha{" "}
                                  {this.state.captchaVerified === true
                                    ? "Verified"
                                    : "Verification"}
                                  <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    className={` ${
                                      this.state.captchaVerified === true
                                        ? " text-success pl-2"
                                        : "d-none"
                                    }`}
                                  />
                                </p>
                                <div
                                  className={`pl-2 ${
                                    this.state.captchaVerified === true
                                      ? "d-none"
                                      : ""
                                  }`}
                                >
                                  <div className="row">
                                    <form>
                                      <ReCAPTCHA
                                        sitekey={this.state.siteKey}
                                        onChange={this.verifyCaptcha}
                                      />
                                    </form>
                                  </div>
                                  {captchaErr && (
                                    <div
                                      style={{
                                        color: "red",
                                        paddingBottom: 10,
                                        paddingTop: 10,
                                      }}
                                    >
                                      {captchaErr}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div> */}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-12">
                      <div className="row pt-4">
                              <div className="col-12">
                                {this.state.isAPIError && (
                                  <div
                                    style={{ color: "red", paddingBottom: 10 }}
                                  >
                                    {this.state.isAPIErrorMsg}
                                  </div>
                                )}
                                <div className="float-right">
                                  {/* <button
                                    className={
                                      "ml-auto mr-4 mr-md-5 btn custombg-secondary customtext-primary pl-4 pr-4 pl-md-5 pr-md-5 " +
                                      (this.state.djoSubmitted ? " d-none" : "")
                                    }
                                    name="cancelSessionId"
                                    id="cancelSessionId"
                                    onClick={(event) =>
                                      this.cancelSessionLink(event)
                                    }
                                  >
                                    Cancel
                                  </button> */}
                                  <button
                                    className="ml-auto btn customtext-secondary pl-4 pr-4 pl-md-5 pr-md-5 mr-5 customborder-primary"
                                    onClick={(event) =>
                                      this.handleCancel()
                                    }
                                    id="cancelSession"
                                  > 
                                  Cancel                                  
                                  </button>
                                  {this.state.isStep6Complete === true &&
                                  this.state.step7 === true ? (
                                  <button
                                    className={
                                      "ml-auto btn custombg-secondary customtext-primary pl-4 pr-4 pl-md-5 pr-md-5 " +
                                      (this.state.djoSubmitted ? " d-none" : "")
                                    }
                                    name="confirmStep7"
                                    id="confirmStep7"
                                    onClick={() =>
                                      // this.handleFormValidationOnSubmit()
                                      (this.state.isStep6Complete === true && this.state.step7 === true) ? this.handleOnClick() : null
                                    }
                                    disabled = {(this.state.isStep6Complete === true && this.state.step7 === true) ? false : true}
                                  >
                                      Submit
                                  </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>

            <DanielsDisclosureForm
              onDanielsDisclosureClicked={this.onDanielsDisclosureClicked}
              isAgreedRevort={this.isAgreedRevort}
              onElectronicAgree={this.onElectronicAgree}
              show={this.state.showPopup}
              isAgreed={this.state.isAgreed}
            />

            <CongratulationDialog 
              showCongratulationModal = {this.state.showCongratulationModal}
              handleContinue = {this.handleContinue}
              handleCancel = {this.handleCancel}
              downPaymentAmt = {this.state.formObj.downPayment}
              paymentPerMonth = {this.state.formObj.paymentPerMonth}
            />

            <Modal
              show={this.state.showSubmitConfirmation}
              onHide={() => this.handleSubmitConfirmationModal()}
            >
              <Modal.Header className="pb-0">
                <b className="customtext-secondary h4">Confirmation</b>
                <FontAwesomeIcon
                  icon={faClose}
                  className="btn"
                  onClick={() => this.handleSubmitConfirmationModal()}
                />
              </Modal.Header>
              <Modal.Body>Are you sure you want to submit?</Modal.Body>
              <Modal.Footer className="border-0">
                <button
                  className="btn mr-2"
                  style={{ backgroundColor: "#1B1B1B", color: "white" }}
                  onClick={() => this.handleSubmitConfirmationModal()}
                >
                  Cancel
                </button>
                <button
                  className="btn ml-2"
                  style={{ backgroundColor: "#5c068c", color: "white" }}
                  onClick={(event) => {
                    this.submitStep7(event);
                  }}
                >
                  Submit
                </button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={this.state.showSubmittedAlert}
              onHide={(event) => this.handleSubmittedAlert(event)}
            >
              <Modal.Header className="pb-0">
                <b className="customtext-secondary h4">Credit Application</b>
                <FontAwesomeIcon
                  icon={faClose}
                  className="btn"
                  onClick={(event) => this.handleSubmittedAlert(event)}
                />
              </Modal.Header>
              <Modal.Body>We have received application. Thank you.</Modal.Body>
              <Modal.Footer className="border-0">
                <form>
                  <button
                    className="btn ml-2"
                    style={{ backgroundColor: "#5c068c", color: "white" }}
                    onClick={(event) => {
                      this.handleSubmittedAlert(event);
                    }}
                  >
                    Close
                  </button>
                </form>
              </Modal.Footer>
            </Modal>

            <Modal show={this.state.validateSessionIdFails}>
              <Modal.Body>Your session is expired, please try again</Modal.Body>
            </Modal>

            <Modal show={this.state.autoPayModal}>
              <Modal.Header className="pb-0">
                <b className="customtext-secondary h4">Confirmation</b>
              </Modal.Header>
              <Modal.Body>
                <p>
                  The item you are purchasing with $0 down payment requires
                  auto-pay enrollment.
                </p>
                <p>Do you want to continue with auto-pay enrollment?</p>
              </Modal.Body>
              <Modal.Footer className="border-0">
                <button
                  className="btn mr-2"
                  style={{ backgroundColor: "#1B1B1B", color: "white" }}
                  onClick={(event) => this.cancelSessionLink(event)}
                >
                  No
                </button>
                <button
                  className="btn ml-2"
                  style={{ backgroundColor: "#5c068c", color: "white" }}
                  onClick={() =>
                    this.setState({ autoPayModal: !this.state.autoPayModal })
                  }
                >
                  Yes
                </button>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.showAppInProgress} >
              <Modal.Header className="pb-0"><b className="customtext-secondary h4">Confirmation</b></Modal.Header>
              <Modal.Body>Your application is being processed, please wait for some time.</Modal.Body>
            </Modal> 
          </main>
        </div>
      </>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//      items: state.token,
//   };
// };

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(shopifyActions, dispatch),
    action: bindActionCreators(HomeAction, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(ShopifyApplyURL);

